import React, {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

export const NewUserInput = props => {
    const {values, setValues, error} = props;

    const isEmailValid = (inputValue, selectValues) => {
        if(!inputValue || inputValue.length === 0) return false;
        if(inputValue.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            let valid = true;
            selectValues.forEach(sV => {
                if(sV.value.toLowerCase() === inputValue.toLowerCase()) valid = false;
            });
            return valid;
        } else {
            return false;
        }
    }

    return <div className="select-filters">
        <CreatableSelect
            isMulti
            onChange={setValues}
            isValidNewOption={isEmailValid}
            formatCreateLabel={V => <span>Invite {V}</span>}
            noOptionsMessage={() => <span>Enter an email address</span>}
            classNamePrefix="sf"
        />
        {error && <span className="input-error">{error}</span>}
    </div>
};
