import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {TeamActionCreators} from "../../redux/actions/team";
import {Link} from "react-router-dom";
import {NewUserInput} from "./NewUserInput";

export const NewUserForm = props => {
    const {setup = false, onSuccess} = props;
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState([]);
    const [errors, setErrors] = useState({form: null, invites: null});
    const [successes, setSuccesses] = useState([]);
    const {teamErr, inviteResults} = useSelector(_S => ({
        teamErr: _S.TeamReducer.teamErr,
        inviteResults: _S.TeamReducer.inviteResults,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {invite} = bindActionCreators(TeamActionCreators, dispatch);

    useEffect(() => {
        if(loading && teamErr) {
            setLoading(false);
            setErrors({form: teamErr});
        } else if(loading && inviteResults) {
            setLoading(false);
            let invitesErrors = [];
            let invitesSuccess = [];
            inviteResults.forEach(x => {
                if(x.status === 'rejected') {
                    invitesErrors.push(x.reason);
                } else {
                    invitesSuccess.push(x.value.email);
                }
            });
            if(invitesErrors.length === 0) {
                setValues([]);
                onSuccess();
            } else {
                setErrors({...errors, invites: invitesErrors});
                setSuccesses(invitesSuccess);
            }
        }
    }, [inviteResults, teamErr]);

    const handleSubmit = () => {
        if(!loading) {
            setErrors({form: null, invites: null});
            setLoading(true);
            const theseValues = values.map(x => ({email: x.value}));
            invite(theseValues);
        }
    }

    return <div className="new-user-form">
            <NewUserInput setValues={setValues}/>
            {errors.invites && <>
                {errors.invites.map(er => <div key={er.email} className="input-error">{er.email} - {er.message}</div>)}
                {successes.map(ss => <div key={ss} className="text-success">{ss} - Successfully invited</div>)}
            </>}
        <div className="buttons">
            {setup && <Link className="btn btn-link" to="/account">Skip</Link>}
            <button className="btn btn-secondary" onClick={handleSubmit} disabled={loading || values.length === 0}>{loading ? "Inviting" : "Invite"}</button>
        </div>
    </div>
}
