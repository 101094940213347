import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {SEO} from "@bytehogs/design-system";
import {ForgotPasswordFormValidation} from "../../lib/formValidation";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Link} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";

export const ForgotPassword = props => {
    const [formStatus, setFormStatus] = useState(0); // 0 setup, 1 loading, 2 error, 3 success
    const [formError, setFormError] = useState(null);
    const {pwErr, resetLinkSent} = useSelector(_S => ({
        resetLinkSent: _S.AuthReducer.resetLinkSent,
        pwErr: _S.AuthReducer.pwErr,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {forgotPassword} = bindActionCreators(AuthActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);

    }, []);

    useEffect(() => {
        if(pwErr && formStatus === 1) {
            setFormError(pwErr);
            setFormStatus(2);
        }
        else if(resetLinkSent && formStatus === 1) setFormStatus(3);
    }, [pwErr, resetLinkSent]);

    const handleSubmit = async (values, {setSubmitting}) => {
        const {email} = values;
        setFormStatus(1);
        setFormError(null);
        try {
            forgotPassword(email);
        } catch(e) {
            setFormError(e.message);
            setFormStatus(2);
        } finally {
            setSubmitting(false);
        }
    }

    const FORM = <Formik
        initialValues={{email: ''}}
        validationSchema={ForgotPasswordFormValidation}
        onSubmit={handleSubmit}>
        {({isSubmitting}) => <>
            <Form>
                <label htmlFor="email" className="sub-title">Email</label>
                <Field className="form-control" name="email" type="email"/>
                <ErrorMessage name="email" component="div"
                              className="input-error"/>
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit"
                        className="btn btn-primary mt-4">{(isSubmitting || formStatus === 1) ? "Sending" : "Send Link"}</button>
            </Form>
        </>}
    </Formik>;

    return <div className="view auth forgot-password">
        <SEO title="Send Password Reset | Right Recruit" slug="/forgot-password" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Forgot Password</h1>
                <Link to="/login" className="auth-link">Login</Link>
                {{
                    0: FORM,
                    1: FORM,
                    2: FORM,
                    3: <div>Thanks! If we have an account on file with that email address, we'll send a link to reset your password!</div>
                }[formStatus]}
            </div>
        </div>
    </div>
}
