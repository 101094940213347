import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../redux/actions/company";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {SortAlphabetically} from "../../lib/sorting";

export const SelectFilters = props => {
    const {type, values, setValues, error} = props;
    const [loading, setLoading] = useState(false);
    const [newOption, setNewOption] = useState(null);
    const {filters, newFilterId} = useSelector(_S => ({
        filters: _S.CompanyReducer.filters,
        newFilterId: _S.CompanyReducer.newFilterId
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getFilters, createFilter} = bindActionCreators(CompanyActionCreators, dispatch);
    const OPTIONS = Object.values(filters).sort((a,b) => SortAlphabetically(a,b,'name')).filter(F => F.type === type).map(F => ({label: F.name, value: Number(F.id)}));

    useEffect(() => {
        if(newFilterId && loading && newOption) {
            dispatch({type: "CLEAR_FILTER_ID"});
            setNewOption({label: newOption, value: newFilterId});
            getFilters();
        }
    }, [newFilterId]);

    useEffect(() => {
        if(loading && newOption) {
            setValues([...values, newOption], type);
            setNewOption(null);
            setLoading(false);
        }
    }, [filters]);

    const handleCreate = value => {
        setLoading(true);
        setNewOption(value);
        createFilter(type, value);
    };

    return <div className="select-filters">
        <CreatableSelect
            isMulti
            isLoading={loading}
            isDisabled={loading}
            onChange={V => setValues(V, type)}
            onCreateOption={handleCreate}
            options={OPTIONS}
            value={values}
            classNamePrefix="sf"
        />
        {error && <span className="input-error">{error}</span>}
    </div>
};
