import { RecruitersActionTypes } from "../../types";
import {analytics, BytehogsAPI} from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";
import axios from "axios";

const SERVICE = "rr-be";

export const RecruitersActionCreators = {
    search: (query, location = null,  loadMore = false) => async (dispatch, getState) => {
        dispatch({
            type: RecruitersActionTypes.SEARCH_RECS_REQ,
        });
        try {
            const offset = loadMore ? getState().RecruitersReducer.recruiters.length : 0;
            let payload = {operation: "search", query, location, offset};
            const response = await BytehogsAPI(SERVICE, "recruiters", payload, STAGE);
            dispatch({
                type: RecruitersActionTypes.SEARCH_RECS_RES,
                error: null,
                payload: response.data,
                reset: !loadMore
            });
        } catch (e) {
            if(e.status === 404) {
                dispatch({
                    type: RecruitersActionTypes.SEARCH_RECS_RES,
                    error: !loadMore ? true : null,
                    payload: {recruiters: []},
                    reset: !loadMore
                });
            } else {
                dispatch({
                    type: RecruitersActionTypes.SEARCH_RECS_RES,
                    error: e.data.message || e.message || "Something went wrong",
                });
            }
        }
    },
    getRecruiterDetails: recruiterId => async (dispatch) => {
        dispatch({
            type: RecruitersActionTypes.GET_RDETAILS_REQ,
        });
        try {
            let payload = {operation: "getDetails", recruiterId};
            const response = await BytehogsAPI(SERVICE, "recruiters", payload, STAGE);
            dispatch({
                type: RecruitersActionTypes.GET_RDETAILS_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: RecruitersActionTypes.GET_RDETAILS_RES,
                error: e.data?.message || e.message || "Something went wrong",
            });
        }
    },
    contact: data => async (dispatch) => {
        dispatch({
            type: RecruitersActionTypes.CONTACT_REC_REQ,
        });
        try {
            let payload = {...data, operation: "contact"};
            const response = await BytehogsAPI(SERVICE, "recruiters", payload, STAGE);
            dispatch({
                type: RecruitersActionTypes.CONTACT_REC_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: RecruitersActionTypes.CONTACT_REC_RES,
                error: e.data?.message || e.message || "Something went wrong",
            });
        }
    },
    verifyTestimonialToken: (testimonialToken, recruiterUserId) => async (dispatch) => {
        dispatch({
            type: RecruitersActionTypes.VERIFY_TOKEN_REQ,
        });
        try {
            let payload = {operation: "verifyTestimonialToken", testimonialToken, recruiterUserId};
            const response = await BytehogsAPI(SERVICE, "recruiters", payload, STAGE);
            dispatch({
                type: RecruitersActionTypes.VERIFY_TOKEN_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: RecruitersActionTypes.VERIFY_TOKEN_RES,
                error: e.data?.message || e.message || "Something went wrong",
            });
        }
    },
    leaveTestimonial: (testimonialToken, recruiterUserId, title, comment, rating, type, userId = null, email = null) => async (dispatch) => {
        dispatch({
            type: RecruitersActionTypes.LEAVE_REVIEW_REQ,
        });
        try {
            let payload = {operation: "leaveTestimonial", testimonialToken, recruiterUserId, title, comment, rating, type, userId, email};
            const response = await BytehogsAPI(SERVICE, "recruiters", payload, STAGE);
            dispatch({
                type: RecruitersActionTypes.LEAVE_REVIEW_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: RecruitersActionTypes.LEAVE_REVIEW_RES,
                error: e.data?.message || e.message || "Something went wrong",
            });
        }
    },
};
