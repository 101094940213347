import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {RecruiterRatingSummary} from "./RecruiterRatingSummary";
import {RecruiterReview} from "./RecruiterReview";

export const RecruiterReviewsCard = props => {
    const [tab, setTab] = useState('company');
    const {pathname} = useLocation();
    const {testimonials, comRating, candRating, reviewToken} = props;
    let ratingCountByStar = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
    const activeTestimonials = testimonials.filter(T => {
        if(T.type === tab) {
            ratingCountByStar[Math.floor(T.rating)]++;
            return T;
        }
    });
    const totalRatings = Object.values(ratingCountByStar).reduce((a,b) => a + b);
    const reviewCount = activeTestimonials.length;
    const isCompany = tab === 'company';

    return <div className="recruiter-reviews-card">
        <div className="row">
            <div className="col-12">
                <div className="tab-row">
                    <div className={"tab-link com " + (isCompany ? "active" : "")} onClick={() => setTab('company')}>
                        Company Reviews
                    </div>
                    <div className={"tab-link cand " + (!isCompany ? "active" : "")} onClick={() => setTab('candidate')}>
                        Candidate Reviews
                    </div>
                    <Link to={pathname + (pathname.endsWith("/") ? '' : '/') + 'reviews'} className="see-more">See More <i className="fas fa-chevron-right"/> </Link>
                </div>
            </div>
            <div className="col-md-4">
                <RecruiterRatingSummary
                    reviewCount={reviewCount}
                    reviewToken={reviewToken}
                    isCompany={isCompany}
                    totalRatings={totalRatings}
                    ratingCounts={ratingCountByStar}
                    rating={isCompany ? comRating : candRating}/>
            </div>
            <div className="col-md-8">
                {reviewCount === 0 && <div className="d-flex h-100 text-center justify-content-center align-items-center">
                    No reviews yet!<br/>
                    Be the first to leave a review
                </div>}
                {activeTestimonials.map((T, i) => (i < 3) && <RecruiterReview key={T.id} {...T} isCompany={isCompany}/>)}
            </div>
        </div>
    </div>
};
