import React, {useState} from "react";
import {shallowEqual, useSelector} from "react-redux";

export const FiltersCard = props => {
    const [isOpen, setOpen] = useState(false);
    const {baseFilters} = useSelector(_S => ({
        baseFilters: _S.CompanyReducer.filters
    }), shallowEqual);
    let {filters, activeFilters, handleClick} = props;
    filters = filters.length > 0 ? filters.filter(x => baseFilters[x]?.type === "industry") : [];
    const allActive = activeFilters.length === filters.length;

    const handleAllClick = () => {
        if(allActive) handleClick([], true);
        else handleClick(filters, true);
    }

    return <div className={"filters-card " +  (isOpen ? "open" : "")}>
        <h5 className="title" onClick={() => setOpen(!isOpen)}>Filter by Category <i className={"d-md-none fas " + (isOpen ? "fa-minus" : "fa-plus")}/> </h5>
        <div className="filters">
            {filters.length === 0 ? <span>No Filters Available</span> :
                <div className={"row filter " + (allActive ? "active" :"")} onClick={handleAllClick}>
                    <div className="col-1">
                        {allActive ? <i className="fas fa-check-square"/> : <i className="fal fa-square"/>}
                    </div>
                    <div className="col-10">
                        <span className="filter-name">All</span>
                    </div>
                </div>}
            {filters.map(F => {
                const theFilter = baseFilters[F];
                return <div key={F} className={"row filter " + (activeFilters.includes(F) ? "active" : "")}
                     onClick={() => handleClick(F)}>
                    <div className="col-1">
                        {activeFilters.includes(F) ? <i className="fas fa-check-square"/> : <i className="fal fa-square"/>}
                    </div>
                    <div className="col-10">
                        <span className="filter-name">{theFilter?.name}</span>
                    </div>
                </div>
            })}
        </div>
    </div>
};
