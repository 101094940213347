import {CompanyActionTypes} from "../../types";

export const initialState = {
    loading: false,
    companyErr: null,
    companyDetails: null,
    companySuccess: null,
    filters: [],
    newFilterId: null,
    logoErr: null,
    newLogoId: null
};

export const CompanyReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case CompanyActionTypes.GET_FILTERS_REQ: {
            return {
                ...state,
                loading: true,
                companyErr: null,
            };
        }
        case CompanyActionTypes.GET_FILTERS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    companyErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                companyErr: null,
                filters: payload.filters.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {})
            };
        }
        case CompanyActionTypes.CREATE_FILTERS_REQ: {
            return {
                ...state,
                loading: true,
                companyErr: null,
            };
        }
        case CompanyActionTypes.CREATE_FILTERS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    companyErr: error
                };
            }
            return {
                ...state,
                loading: false,
                companyErr: null,
                newFilterId: payload.filterId
            };
        }
        case 'CLEAR_FILTER_ID': {
            return {
                ...state,
                newFilterId: null
            };
        }
        case CompanyActionTypes.GET_COM_REQ: {
            return {
                ...state,
                loading: true,
                companyErr: null,
            };
        }
        case CompanyActionTypes.GET_COM_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    companyErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                companyErr: null,
                companyDetails: payload.company
            };
        }
        case CompanyActionTypes.UPDATE_COM_REQ: {
            return {
                ...state,
                loading: true,
                companyErr: null,
                companySuccess: false
            };
        }
        case CompanyActionTypes.UPDATE_COM_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    companyErr: error,
                    companySuccess: false
                };
            }
            return {
                ...state,
                loading: false,
                companyErr: null,
                companySuccess: true
            };
        }
        case CompanyActionTypes.UPLOAD_LOGO_REQ: {
            return {
                ...state,
                loading: true,
                logoErr: null,
                newLogoId: null
            };
        }
        case CompanyActionTypes.UPLOAD_LOGO_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    logoErr: error
                };
            }
            return {
                ...state,
                loading: false,
                logoErr: null,
                newLogoId: payload.fileId
            };
        }
        case CompanyActionTypes.UPDATE_FILTERS_REQ: {
            return {
                ...state,
                loading: true,
                companyErr: null,
                companySuccess: false
            };
        }
        case CompanyActionTypes.UPDATE_FILTERS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    companyErr: error,
                    companySuccess: false
                };
            }
            return {
                ...state,
                loading: false,
                companyErr: null,
                companySuccess: true
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
