import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {SEO} from "@bytehogs/design-system";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Link} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import {ResetPasswordFormValidation} from "../../lib/formValidation";

export const SetPassword = props => {
    const [formStatus, setFormStatus] = useState(-2); //-2 checking token, -1 token invalid,  0 token valid, 1 loading, 2 error, 3 success
    const [formError, setFormError] = useState(null);
    const {token} = useParams();
    const {pwErr, pwSuccess, pwTokenSuccess} = useSelector(_S => ({
        pwSuccess: _S.AuthReducer.pwSuccess,
        pwErr: _S.AuthReducer.pwErr,
        pwTokenSuccess: _S.AuthReducer.pwTokenSuccess,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {setPassword, checkPwToken} = bindActionCreators(AuthActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        checkPwToken(token);
    }, []);

    useEffect(() => {
        if(pwErr && formStatus === -2) {
            setFormStatus(-1)
        } else if(pwErr && formStatus === 1) {
            setFormError(pwErr);
            setFormStatus(2);
        }
        else if(pwSuccess && formStatus === 1) setFormStatus(3);
    }, [pwErr, pwSuccess]);

    useEffect(() => {
        if(pwTokenSuccess && formStatus === -2) {
            setFormStatus(0)
        }
    }, [pwTokenSuccess]);

    const handleSubmit = async (values, {setSubmitting}) => {
        const {password} = values;
        setFormStatus(1);
        setFormError(null);
        try {
            setPassword(password, token);
        } catch(e) {
            setFormError(e.message);
            setFormStatus(2);
        } finally {
            setSubmitting(false);
        }
    }

    const FORM = <Formik
        initialValues={{password: '', passwordConf: ''}}
        validationSchema={ResetPasswordFormValidation}
        onSubmit={handleSubmit}>
        {({isSubmitting}) => <>
            <Form>
                <label htmlFor="password" className="sub-title">New Password</label>
                <Field className="form-control" name="password" type="password"/>
                <ErrorMessage name="password" component="div" className="input-error"/>
                <label htmlFor="passwordConf" className="sub-title">Confirm Password</label>
                <Field className="form-control" name="passwordConf" type="password"/>
                <ErrorMessage name="passwordConf" component="div" className="input-error"/>
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit" className="btn btn-primary mt-4">{(isSubmitting || formStatus === 1) ? "Resetting" : "Reset Password"}</button>
            </Form>
        </>}
    </Formik>;

    return <div className="view auth forgot-password">
        <SEO title="Reset your Password | Right Recruit" slug="/set-password" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Set Password</h1>
                {{
                    '-2': <div>Verifying Reset Link</div>,
                    '-1': <div><p>Sorry, this reset link is invalid or has expired.</p> <Link to="/forgot-password">Request New Reset Link</Link> or <Link to="/login">Login</Link></div>,
                    0: FORM,
                    1: FORM,
                    2: FORM,
                    3: <div>You've changed your password! <Link to="/login">Login Here</Link> </div>
                }[formStatus]}
            </div>
        </div>
    </div>
}
