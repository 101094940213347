import React, {useState} from "react";
const ratingStarActiveBlue = "https://client-upload.bytehogs.com/rr/icons/star-blue.svg";
const ratingStarActiveGreen = "https://client-upload.bytehogs.com/rr/icons/star-green.svg";
const ratingStar = "https://client-upload.bytehogs.com/rr/icons/star-empty.svg";

export const RatingContainer = props => {
    const {rating, hideRating = false, company = false} = props;
    const ratingStarActive = company ? ratingStarActiveGreen: ratingStarActiveBlue;
    return <div className={"rating-container "} title={rating}>
        <img src={rating >= 1 ? ratingStarActive : ratingStar} className="rating-star" alt={1}/>
        <img src={rating >= 2 ? ratingStarActive : ratingStar} className="rating-star" alt={2}/>
        <img src={rating >= 3 ? ratingStarActive : ratingStar} className="rating-star" alt={3}/>
        <img src={rating >= 4 ? ratingStarActive : ratingStar} className="rating-star" alt={4}/>
        <img src={rating >= 5 ? ratingStarActive : ratingStar} className="rating-star" alt={5}/>
        {(!hideRating) && <span className="rating">{Number(rating).toFixed(1).replace('.0', '')}</span>}
    </div>
}

export const ClickableRatingContainer = props => {
    const {rating, company = false, onClick} = props;
    const [hoverValue, setHoverValue] = useState(0);
    const ratingStarActive = company ? ratingStarActiveGreen: ratingStarActiveBlue;

    const onEnter = (value) => setHoverValue(value);
    const onExit = () => setHoverValue(0);
    const values = [1,2,3,4,5];

    return <div className={"rating-container clickable"}>
        {values.map(V => <img
            key={V}
            src={(hoverValue || rating) >= V ? ratingStarActive : ratingStar}
            className="rating-star"
            alt={V}
            onClick={() => onClick(V)}
            onMouseEnter={() => onEnter(V)}
            onMouseLeave={onExit}/>)}
    </div>
}
