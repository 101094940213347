import React, {useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RecruiterRatingRow} from "./RecruiterRatingRow";

export const RecruiterCard = props => {
    const {baseFilters} = useSelector(_S => ({
        baseFilters: _S.CompanyReducer.filters
    }), shallowEqual);
    const {id, name, bio, avgCompanyRating, totalCompanyRaters, avgCandidateRating, totalCandidateRaters, filters, logo} = props;
    const [actualFilters, setActualFilters] = useState([]);

    useEffect(() => {
        let temp = [];
        filters?.forEach(F => {
            const filter = baseFilters[F];
            if(filter && filter.type === 'industry') temp.push(filter);
        });
        setActualFilters(temp);
    }, [filters]);

    return <Link to={"/recruiters/"+id} className="wrapper-link">
        <div className="recruiter-card">
            <div className="row">
                <div className="col-md-3">
                    {logo ? <img src={logo} loading="lazy" className="rec-logo"/> : <div className="img"/>}
                </div>
                <div className="col-md-9">
                    <h5 className="title">{name}</h5>
                    <RecruiterRatingRow
                        avgCompanyRating={avgCompanyRating}
                        totalComs={totalCompanyRaters}
                        avgCandidateRating={avgCandidateRating}
                        totalCands={totalCandidateRaters}/>
                    <span className="sub-title">Description</span>
                    <p className="bio">{bio.length < 295 ? bio : bio.substr(0, 295).trim() + '...'}</p>
                    {actualFilters?.length > 0 && <>
                        <span className="sub-title">Categories</span>
                        <div className="categories">
                            {actualFilters.map((F, i) => i < 3 && <div className="category" key={F.id}>{F.name}</div>)}
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </Link>
};
