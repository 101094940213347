import React, {useEffect, useRef, useState} from "react";
import AvatarEditor from "react-avatar-editor";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../redux/actions/company";

export const LogoUpload = props => {
    const {currentLogo, updateValue} = props;
    const editor = useRef();
    const [loading, setLoading] = useState(false);
    const [displayImage, setDisplayImage] = useState(currentLogo || null);
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileExt, setFileExt] = useState(null);
    const [error, setError] = useState(null);
    const {logoErr, newLogoId} = useSelector(_S => ({
        logoErr: _S.CompanyReducer.logoErr,
        newLogoId: _S.CompanyReducer.newLogoId
    }), shallowEqual);
    const dispatch = useDispatch();
    const {uploadLogo} = bindActionCreators(CompanyActionCreators, dispatch);

    useEffect(() => {
        if(loading) {
            if(logoErr) {
                setLoading(false);
                setError(logoErr);
            } else if(newLogoId) {
                updateValue('logo', newLogoId);
                setLoading(false);
                setImage(null)
                setFileName(null);
                setFileType(null);
                setFileExt(null);
            }
        }
    }, [logoErr, newLogoId]);

    const onFileChange = e => {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        let splitted = files[0].name.split('.')
        let ext = splitted[splitted.length - 1];
        let type = files[0].type;
        setError(null);
        if(['image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
            createImage(files[0], files[0].type, ext)
        } else {
            setError("File Type not supported");
        }
    }

    const createImage = (file, fType, ext) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            setImage(e.target.result);
            setFileType(fType);
            setFileExt(ext);
            setFileName(file.name);
        };
        reader.readAsDataURL(file);
    }

    const handleUpload = () => {
        if(editor && !loading) {
            const canvas = editor.current.getImage()
            setLoading(true);
            setError(null);
            canvas.toBlob(async theBlob => {
                try {
                    setDisplayImage(canvas.toDataURL());
                    uploadLogo(theBlob, fileName, fileType, fileExt);
                } catch(e) {
                    console.log("Caught exception", e)
                    setLoading(false);
                    setError("Something went wrong. Refresh and try again.");
                }
            })
        }
    }

    return <div className={"logo-upload " + (image ? "active" : "")}>

        <input type='file' id='single' onChange={onFileChange} accept="image/png image/jpg image/jpeg" className="inputFile"/>
        {image ? <div className="pp-editor">
            <AvatarEditor
                ref={editor}
                width={400}
                height={400}
                image={image} />
            <div className="rotate">
                {/* <img src={rLeft} onClick={() => this.rotate("left")} />
                    <img src={rRight} onClick={() => this.rotate("right")} />*/}
            </div>
            <div className="bottom">
                <div className="buttons">
                    <label htmlFor="single" className="btn btn-link">Choose an image</label>
                    <button className="btn btn-primary" onClick={handleUpload} disabled={loading} type="button">{loading ? "Uploading..." : "Upload"}</button>
                </div>
            </div>
        </div> : <div className="choose-file">

            <label htmlFor="single" className="">
                {displayImage ? <img src={displayImage}/> : <label htmlFor="single" className="btn btn-primary" type="button">Choose an Image</label>}
            </label>
        </div>}
        {error && <span className="input-error">{error}</span>}
    </div>
}
