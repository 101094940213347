import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../redux/actions/company";
import {NavLink} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";

export const AccountNavigation = props => {
  const {companyDetails, userDetails} = useSelector(_S => ({
    companyDetails: _S.CompanyReducer.companyDetails,
    userDetails: _S.AuthReducer.userDetails,
  }), shallowEqual);
  const dispatch = useDispatch();
  const {getCurrentUser} = bindActionCreators(AuthActionCreators, dispatch);
  const {getCompany} = bindActionCreators(CompanyActionCreators, dispatch);

  useEffect(() => {
    if(!companyDetails) getCompany();
    if(JSON.stringify(userDetails) === '{}') getCurrentUser();
  }, []);

  const isRecruiter = companyDetails?.isRecruiter === 1;
  return <div className="account-navigation">
    <NavLink to="/account" exact>Personal Details</NavLink>
    {companyDetails && <NavLink to="/account/company" exact>{isRecruiter ? "Recruiter" : "Company"} Details</NavLink>}
    {isRecruiter && <NavLink to="/account/company/filters">Recruiter Filters</NavLink>}
    {(userDetails?.roleId === 1 && companyDetails) && <NavLink to="/account/team">Manage Team</NavLink>}
  </div>
};
