import { AuthActionTypes } from "../../types";

export const initialState = {
    loading: false,
    isAuthed: false,
    registerErr: null,
    registerSuccess: null,
    loginErr: null,
    pwErr: null,
    pwSuccess: false,
    pwTokenSuccess: false,
    resetLinkSent: false,
    profileErr: null,
    profileSuccess: null,
    userDetails: {},
};

export const AuthReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case AuthActionTypes.AUTH_STATUS: {
            if (error) {
                return {
                    ...state,
                    isAuthed: false,
                };
            }
            return {
                ...state,
                isAuthed: true,
            };
        }
        case AuthActionTypes.REGISTER_REQ: {
            return {
                ...state,
                loading: true,
                registerErr: null,
            };
        }
        case AuthActionTypes.REGISTER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    registerErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                registerErr: null,
                registerSuccess: true
            };
        }
        case AuthActionTypes.LOGIN_REQ: {
            return {
                ...state,
                loading: true,
                loginErr: null,
            };
        }
        case AuthActionTypes.LOGIN_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    loginErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                loginErr: null,
                isAuthed: true,
            };
        }
        case AuthActionTypes.FORGOT_PW_REQ: {
            return {
                ...state,
                loading: true,
                resetLinkSent: false
            };
        }
        case AuthActionTypes.FORGOT_PW_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    pwErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                pwErr: null,
                resetLinkSent: true
            };
        }
        case AuthActionTypes.CHECK_PW_REQ: {
            return {
                ...state,
                loading: true,
            };
        }
        case AuthActionTypes.CHECK_PW_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    pwErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                pwErr: null,
                pwTokenSuccess: true
            };
        }
        case AuthActionTypes.SET_PW_REQ: {
            return {
                ...state,
                loading: true,
                pwSuccess: null,
                pwErr: null
            };
        }
        case AuthActionTypes.SET_PW_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    pwErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                pwErr: null,
                pwSuccess: true
            };
        }
        case AuthActionTypes.GET_USER_REQ: {
            return {
                ...state,
                loading: true,
                profileErr: null,
            };
        }
        case AuthActionTypes.GET_USER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    profileErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                profileErr: null,
                userDetails: payload.user
            };
        }
        case AuthActionTypes.UPDATE_USER_REQ: {
            return {
                ...state,
                loading: true,
                profileErr: null,
                profileSuccess: null
            };
        }
        case AuthActionTypes.UPDATE_USER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    profileErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                profileErr: null,
                profileSuccess: true
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
