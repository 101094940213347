import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import {AuthReducer} from "./auth";
import {RecruitersReducer} from "./recruiters";
import {CompanyReducer} from "./company";
import {TeamReducer} from "./team";

const reducers = combineReducers({
    form: formReducer,
    AuthReducer,
    RecruitersReducer,
    CompanyReducer,
    TeamReducer
});

export default reducers;
