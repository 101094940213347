import React, {useEffect, useState} from "react";
import {AccountNavigation} from "../../components/account/AccountNavigation";
import {SEO} from "@bytehogs/design-system";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";

export const PersonalDetails = props => {
    const [detailsLoading, setDetLoading] = useState(false);
    const [pwLoading, setPwLoading] = useState(false);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({detailsForm: null, name: null, password: null, newPassword: null, passwordForm: null});
    const {userDetails, profileErr, profileSuccess, pwErr, pwSuccess} = useSelector(_S => ({
        pwErr: _S.AuthReducer.pwErr,
        pwSuccess: _S.AuthReducer.pwSuccess,
        profileErr: _S.AuthReducer.profileErr,
        profileSuccess: _S.AuthReducer.profileSuccess,
        userDetails: _S.AuthReducer.userDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getCurrentUser, updateUser, updatePassword} = bindActionCreators(AuthActionCreators, dispatch);
    const {email, roleId} = userDetails;

    useEffect(() => {
        setName(userDetails?.name);
    }, [userDetails]);

    useEffect(() => {
        if(detailsLoading && (profileErr || profileSuccess)) {
            if(profileErr) setErrors({...errors, detailsForm: profileErr});
            else if(profileSuccess) getCurrentUser();
            setDetLoading(false);
        }
    }, [profileErr, profileSuccess]);

    useEffect(() => {
        if(pwLoading && (pwErr || pwSuccess)) {
            if(pwErr) setErrors({...errors, passwordForm: pwErr});
            else if(pwSuccess) {
                setPassword('');
                setNewPassword('');
                setErrors({...errors, passwordForm: null});
            }
            setPwLoading(false);
        }
    }, [pwErr, pwSuccess]);

    const handleDetailsSubmit = () => {
        if(!detailsLoading) {
            setDetLoading(true);
            if(!name || name.length < 3) setErrors({...errors, name: "Name should be at least 2 characters."});
            else updateUser({name});
        }
    }

    const handlePasswordSubmit = () => {
        if(!pwLoading) {
            setPwLoading(true);
            let theseErrs = {...errors};
            if(!password || password.length < 6) theseErrs.password = "Current Password should be at least 6 characters.";
            else theseErrs.password = null;
            if(!newPassword || newPassword.length < 6) theseErrs.newPassword = "New Password should be at least 6 characters.";
            else theseErrs.newPassword = null;
            if(theseErrs.password || theseErrs.newPassword) setErrors(theseErrs);
            else updatePassword(password, newPassword);
        }
    }

    return <div className="view account personal">
        <SEO title="Manage Your Account | Right Recruit" slug="/account" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <AccountNavigation/>
            <div className="account-card">
                <h4>Your Details</h4>
                <label className="sub-title">Your Name</label>
                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)}/>
                {errors.name && <span className="input-error">{errors.name}</span>}
                <span className="sub-title">Your Email</span>
                <span>{email}</span>
                <span className="sub-title">Your Role</span>
                <span>{roleId === 1 ? "Admin" : "Member"}</span>
                {errors.detailsForm && <span className="input-error">{errors.detailsForm}</span>}
                <button className="btn btn-secondary mt-4 d-block" disabled={detailsLoading || name === userDetails.name} onClick={handleDetailsSubmit}>{detailsLoading ? "Saving" : "Save"}</button>
            </div>
            <div className="account-card">
                <h4>Update Password</h4>
                <label className="sub-title">Current Password</label>
                <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)}/>
                {errors.password && <span className="input-error">{errors.password}</span>}
                <label className="sub-title">New Password</label>
                <input type="password" className="form-control" value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                {errors.newPassword && <span className="input-error">{errors.newPassword}</span>}
                {errors.passwordForm && <span className="input-error">{errors.passwordForm}</span>}
                <button className="btn btn-secondary mt-4 d-block" disabled={pwLoading || password === newPassword} onClick={handlePasswordSubmit}>{pwLoading ? "Updating" : "Update"}</button>
            </div>
        </div>
    </div>
}
