import React, {useEffect, useState} from "react";
import {SEO} from '@bytehogs/design-system';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RecruitersActionCreators} from "../../redux/actions/recruiters";
import {SearchBar} from "../../components/SearchBar";
import {RecruitersList} from "../../components/recruiters";
import {FiltersCard} from "../../components/filters/FiltersCard";
import {Pagination} from "../../components/Pagination";
import {useLocation} from "react-router-dom/cjs/react-router-dom";
const RESULTS_PER_PAGE = 15;

const SearchRecruiters = props => {
    const LOC = useLocation();
    const SearchParams = new URLSearchParams(LOC.search);
    const theQuery = SearchParams.get('query') || "";
    let theCity = SearchParams.get('city') || null;
    theCity = theCity === "null" ? "" : theCity;
    const [loading, setLoading] = useState(false);
    const [recruiters, setRecruiters] = useState([]);
    //const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState(theQuery);
    const [location, setLocation] = useState(theCity);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        //if(props.recruiters.length === 0) {
            search();
            setLoading(true);
        //}
    }, []);

    useEffect(() => {
        setLocation(theCity || "");
        setQuery(theQuery || "");
    }, [theCity, theQuery]);

    const search = (loadMore = false) => {
        props.recActions.search(query, location, loadMore);
    }

    useEffect(() => {
        //if(props.recruiters.length !== recruiters.length) {
            let recs = [];
            let theseFilters = new Set();
            props.recruiters.forEach(R => {
                R.filters?.forEach(F => theseFilters.add(F));
                recs.push(R);
            });
            setRecruiters(recs);
            //setFilters([...theseFilters]);
        //}
        if(loading) setLoading(false);
    }, [props.recruiters]);

    useEffect(() => {
        if(activeFilters.length > 0 ) {
            let recruiters = [];
            props.recruiters.forEach(R => {
                if(R.filters && activeFilters.every(i => R.filters.includes(i))) {
                    recruiters.push(R);
                }
            });
            setRecruiters(recruiters);
        } else {
            if(props.recruiters.length !== recruiters.length) {
                setRecruiters(props.recruiters);
            }
        }
    }, [activeFilters]);

    const handleFilterClick = (filterId, all = false) => {
        let newData = [...activeFilters];
        if(all) {
            newData = filterId;
        } else {
            if (newData.includes(filterId)) newData.splice(newData.indexOf(filterId), 1);
            else newData.push(filterId);
        }
        setActiveFilters(newData);
        goToPage(1, true, false);
    }

    const goToPage = (pgNum, filter = false, scrollToTop = true) => {
        if(Math.ceil(recruiters.length/RESULTS_PER_PAGE) < pgNum && !filter) search(true);
        setPage(pgNum);
        if(scrollToTop) {
            document.querySelector('body').scrollTo(0, 0);
            window.scrollTo(0, 0);
        }
    }

    const TOTAL_RESULTS = activeFilters.length > 0 ? recruiters.length : props.totalRecruiters;
    return <div className="view recruiter search">
        <SEO title="Search Recruiters | Right Recruit" slug="" description="" image="" locale="en_GB" type="website" />
        <SearchBar type="recruiters"/>
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <FiltersCard filters={props.filters} activeFilters={activeFilters} handleClick={handleFilterClick}/>
                </div>
                <div className="col-md-8">
                    <RecruitersList recruiters={recruiters} currentPage={page} limitPerPage={RESULTS_PER_PAGE} totalResults={TOTAL_RESULTS} loading={loading}/>
                    <Pagination resultsPerPage={RESULTS_PER_PAGE} totalResults={TOTAL_RESULTS} currentPage={page} goToPage={goToPage} loading={loading}/>
                </div>
            </div>
        </div>

    </div>
}

const mapStateToProps = (state) => ({
    recruiters: state.RecruitersReducer.recruiters,
    totalRecruiters: state.RecruitersReducer.totalRecruiters,
    filters: state.RecruitersReducer.filters,
});

const mapDispatchToProps = (dispatch) => ({
    recActions: bindActionCreators(RecruitersActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecruiters);
