import {AuthActionTypes} from "../../types";
import {BytehogsAPI} from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";

const SERVICE = "rr-be";

export const AuthActionCreators = {
    checkToken: () => async (dispatch) => {
        const sessionToken = await localStorage.getItem("sToken");
        const refreshToken = await localStorage.getItem("rToken");
        if (!sessionToken || !refreshToken) {
            dispatch({
                type: AuthActionTypes.AUTH_STATUS,
                error: "No Auth",
            });
        } else {
            try {
                await BytehogsAPI(SERVICE, "token", {
                    operation: "check",
                    sessionToken,
                    refreshToken,
                }, STAGE);
                dispatch({
                    type: AuthActionTypes.AUTH_STATUS,
                    error: null,
                });
            } catch (e) {
                dispatch({
                    type: AuthActionTypes.AUTH_STATUS,
                    error: "No Auth",
                });
            }
        }
    },
    register: (name, email, password) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.REGISTER_REQ,
        });
        try {
            await BytehogsAPI(SERVICE, "register", {name, email, password}, STAGE, null, true);
            /*analytics.event({
                category: 'account',
                action: 'register',
                label: 'register',
                value: 0
            });*/
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    login: (email, password) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.LOGIN_REQ,
        });
        try {
            const response = await BytehogsAPI(SERVICE, "login", {email, password}, STAGE, null, true);
            localStorage.setItem("sToken", response.data.sessionToken);
            localStorage.setItem("rToken", response.data.refreshToken);
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    forgotPassword: email => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.FORGOT_PW_REQ,
        });
        try {
            let payload = {email, operation: "forgot" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.FORGOT_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.FORGOT_PW_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    checkPwToken: token => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.CHECK_PW_REQ,
        });
        try {
            let payload = { token, operation: "checkToken" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.CHECK_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.CHECK_PW_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    setPassword: (newPassword, token) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.SET_PW_REQ,
        });
        try {
            let payload = {newPassword, token, operation: "set" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    updatePassword: (currentPassword, newPassword) => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.SET_PW_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, currentPassword, newPassword, operation: "update" };
            await BytehogsAPI(SERVICE, "password", payload, STAGE);
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: null,
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.SET_PW_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    getCurrentUser: () => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.GET_USER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getCurrent"};
            const response = await BytehogsAPI(SERVICE, "user", payload, STAGE);
            dispatch({
                type: AuthActionTypes.GET_USER_RES,
                error: null,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: AuthActionTypes.GET_USER_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    updateUser: data => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.UPDATE_USER_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {...data, sessionToken, operation: "update"}
            const response = await BytehogsAPI(SERVICE, 'user', payload, STAGE)
            dispatch({
                type: AuthActionTypes.UPDATE_USER_RES,
                payload: response.data
            })
        } catch (e) {
            console.log("e", e)
            dispatch({
                type: AuthActionTypes.UPDATE_USER_RES,
                error: e?.data?.message || e.message ||  "Something went wrong"
            })
        }
    },
    logout: () => async (dispatch) => {
        await localStorage.removeItem("sToken");
        await localStorage.removeItem("rToken");
        dispatch({
            type: "LOGOUT",
        });
    },
};
