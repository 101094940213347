import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const webRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const ReviewFormValidation = Yup.object({
    type: Yup.string()
        .required('Required'),
    rating: Yup.number().integer()
        .min(1, 'Must be above 0')
        .max(5, 'Must be below 6')
        .required('Required'),
    title: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
    comment: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required'),
    userId: Yup.number().integer().nullable(),
    email: Yup.string().when('userId', {
        is: (null || undefined),
        then: Yup.string()
            .email()
            .required('Required')
    })
});

const ContactFormValidation = Yup.object({
    subject: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Required'),
    message: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required'),
    userId: Yup.number().integer().nullable(),
    email: Yup.string().when('userId', {
        is: (null || undefined),
        then: Yup.string()
            .email()
            .required('Required')
    })
});

const LoginFormValidation = Yup.object({
    email: Yup.string()
        .email('Must be a valid email')
        .required('Required'),
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required')
});

const RegisterFormValidation = Yup.object({
    firstName: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    email: Yup.string()
        .email('Must be a valid email')
        .required('Required'),
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
});

const ForgotPasswordFormValidation = Yup.object({
    email: Yup.string()
        .email('Must be a valid email')
        .required('Required')
});

const ResetPasswordFormValidation = Yup.object({
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required'),
    passwordConf: Yup.string()
        .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.password === value
        }).required('Required')
});

const CreateCompanyFormValidation = Yup.object({
    name: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    email: Yup.string()
        .email('Must be a valid email')
        .required('Required'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Invalid Phone Number')
        .min(11, 'Must be 11 characters')
        .required('Required'),
    website: Yup.string()
        .matches(webRegExp, 'Invalid Website')
        .required('Required'),
    linkedin: Yup.string()
        .matches(webRegExp, 'Invalid LinkedIn URL'),
    facebook: Yup.string()
        .matches(webRegExp, 'Invalid Facebook URL'),
    twitter: Yup.string()
        .matches(webRegExp, 'Invalid Twitter URL'),
});

const CreateRecruiterFormValidation = Yup.object({
    name: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    logo: Yup.string().required('Logo is required'),
    bio: Yup.string()
        .min(20, 'Must be at least 20 characters')
        .required('Required'),
    city: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    country: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    email: Yup.string()
        .email('Must be a valid email')
        .required('Required'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Invalid Phone Number')
        .min(11, 'Must be 11 characters')
        .required('Required'),
    website: Yup.string()
        .matches(webRegExp, 'Invalid Website')
        .required('Required'),
    linkedin: Yup.string()
        .matches(webRegExp, 'Invalid LinkedIn URL'),
    facebook: Yup.string()
        .matches(webRegExp, 'Invalid Facebook URL'),
    twitter: Yup.string()
        .matches(webRegExp, 'Invalid Twitter URL'),
});

const NewJoinFormValidation = Yup.object({
    name: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .required('Required'),
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
});

const ExistingJoinFormValidation = Yup.object({
    password: Yup.string()
        .min(6, 'Must be at least 6 characters')
        .required('Required')
});

export {ReviewFormValidation, ContactFormValidation, LoginFormValidation, RegisterFormValidation, ForgotPasswordFormValidation, ResetPasswordFormValidation, CreateCompanyFormValidation, CreateRecruiterFormValidation, NewJoinFormValidation, ExistingJoinFormValidation};
/*
The following fields should be present on the create company account page

Company name (required)
Company contact email (required)
Company contact phone (required)
Company website (required)
LinkedIn (optional)
Facebook (optional)
Twitter (optional)


The following fields should be present on the create a recruiter account page:

    Company name (required)
Company (optional)
About (optional)
Address (optional)
Company contact email (required)
Company contact phone (required)
Company website (required)
LinkedIn (optional)
Facebook (optional)
Twitter (optional)


*/
