import React, {useEffect, useState} from "react";
import {SEO} from '@bytehogs/design-system';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {SearchBar} from "../../components/SearchBar";
import {bindActionCreators} from "redux";
import {RecruitersActionCreators} from "../../redux/actions/recruiters";
import {RecruiterInfoCard, RecruiterBioCard, RecruiterContactCard, RecruiterReviewsCard} from "../../components/recruiters";
import {RecruiterContactFormCard} from "../../components/recruiters/RecruiterContactFormCard";

export const RecruiterDetails = props => {
    const [initLoading, setInitLoading] = useState(0);
    const [recruiter, setRecruiter] = useState(null);
    const { id } = useParams();
    const {recDetails, recruiterErr} = useSelector(_S => ({
        recruiterErr: _S.RecruitersReducer.recruiterErr,
        recDetails: _S.RecruitersReducer.recDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getRecruiterDetails} = bindActionCreators(RecruitersActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(!recDetails[id]) {
            setInitLoading(1);
            getRecruiterDetails(id);
        } else {
            setInitLoading(2);
            setRecruiter(recDetails[id]);
        }
    }, []);

    useEffect(() => {
        if(initLoading === 1) {
            if(recDetails[id]) setRecruiter(recDetails[id]);
            setInitLoading(2)
        }
    }, [recDetails]);

    useEffect(() => {
        if(initLoading === 1 && recruiterErr) setInitLoading(2);
    }, [recruiterErr]);

    const LOADING_CONTENT = <div className="col-12 text-center"><i className="fas fa-spinner fa-spin"/> </div>;

    return <div className="view recruiter details">
        <SEO title={(recruiter ? recruiter.name : "Recruiter Details") + " | Right Recruit"} slug="" description="" image="" locale="en_GB" type="website" />
        <SearchBar type="recruiters"/>
        <div className="container">
            <div className="row">
                {{  0: LOADING_CONTENT,
                    1: LOADING_CONTENT,
                    2: <>
                        {!recruiter ? <div className="col-12">Recruiter Not Found</div> : <>
                            <div className="col-md-8">
                                <RecruiterInfoCard recruiter={recruiter}/>
                                <RecruiterBioCard bio={recruiter.bio}/>
                                <div className="d-md-none">
                                    <RecruiterContactCard recruiter={recruiter}/>
                                </div>
                                <RecruiterReviewsCard
                                    testimonials={recruiter.testimonials}
                                    reviewToken={recruiter.testimonialToken}
                                    comRating={recruiter.avgCompanyRating}
                                    candRating={recruiter.avgCandidateRating}/>
                                <RecruiterContactFormCard recruiterId={recruiter.id}/>
                            </div>
                            <div className="col-md-4">
                                <div className="d-none d-md-block">
                                    <RecruiterContactCard recruiter={recruiter}/>
                                </div>
                            </div>
                            </>}
                    </>
                   }[initLoading]}
            </div>
        </div>
    </div>
}
