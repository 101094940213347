import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {RecruitersActionCreators} from "../../redux/actions/recruiters";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ContactFormValidation} from "../../lib/formValidation";

export const RecruiterContactFormCard = props => {
    const [status, setStatus] = useState(0); // 0 form, 1 loading, 2 submitted, 3 error
    const [formError, setFormError] = useState(null);
    const {recruiterId} = props;
    const {userDetails, recruiterErr, contactSuccess} = useSelector(_S => ({
        recruiterErr: _S.RecruitersReducer.recruiterErr,
        contactSuccess: _S.RecruitersReducer.contactSuccess,
        userDetails: _S.AuthReducer.userDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {contact} = bindActionCreators(RecruitersActionCreators, dispatch);
    const userId = userDetails?.id;

    useEffect(() => {
        if(status === 1 && recruiterErr) {
            setStatus(3);
            setFormError(recruiterErr);
        } else if(status === 1 && contactSuccess) {
            setStatus(2);
        }
    }, [recruiterErr, contactSuccess]);

    const handleSubmit = (values) => {
        if(status !== 1) {
            setStatus(1);
            contact({...values, recruiterId});
        }
    };

    const FORM = <Formik
        initialValues={{subject: '', message: '', email: '', userId}}
        validationSchema={ContactFormValidation}
        onSubmit={handleSubmit}>
        {({isSubmitting}) => <>
            <Form>
                {!userId && <>
                    <label htmlFor="email" className="sub-title">Your Email</label>
                    <Field className="form-control" name="email" type="text"/>
                    <ErrorMessage name="email" component="div"
                                  className="input-error"/>
                </>}
                <label htmlFor="subject" className="sub-title">Subject</label>
                <Field className="form-control" name="subject" type="text"/>
                <ErrorMessage name="subject" component="div"
                              className="input-error"/>
                <label htmlFor="message" className="sub-title">Message</label>
                <Field className="form-control" name="message" type="text"
                       as="textarea" rows={4}/>
                <ErrorMessage name="message" component="div"
                              className="input-error"/>
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit"
                        className="btn btn-secondary mt-4 ml-auto d-block">{(status === 1) ? "Sending" : "Send Message"}</button>
            </Form>
        </>}
    </Formik>

    return <div className="recruiter-contact-form-card" id="contact">
        <span className="sub-title">Contact</span>
        {{
            0: FORM,
            1: FORM,
            2: <div>Message Sent!</div>,
            3: FORM,
        }[status]}
    </div>
};
