import React from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    CreateCompanyFormValidation,
    CreateRecruiterFormValidation,
    ExistingJoinFormValidation,
    NewJoinFormValidation
} from "./formValidation";
import {LogoUpload} from "../components/account/LogoUpload";
import {Link} from "react-router-dom";

export const COMPANY_FORM = props => {
    const DEFAULT_VALUES = {name: '', logo: false, bio: '', city: '', country: '', email: '', phone: '', website: '', linkedin: '', facebook: '', twitter: ''};
    const {isRecruiter, handleSubmit, formError, initialValues = DEFAULT_VALUES, loading = false, setup = false} = props;

    return <Formik
        initialValues={initialValues}
        validationSchema={isRecruiter ? CreateRecruiterFormValidation : CreateCompanyFormValidation}
        onSubmit={handleSubmit}>
        {({setFieldValue}) => <>
            <Form>
                <label htmlFor="name" className="sub-title">Company Name</label>
                <Field className="form-control" name="name" type="text"/>
                <ErrorMessage name="name" component="div" className="input-error"/>
                <label htmlFor="logo" className="sub-title">Company Logo</label>
                <LogoUpload currentLogo={initialValues.logoUrl} updateValue={setFieldValue}/>
                <Field type="text" name="logo" className='d-none'/>
                <ErrorMessage name="logo" component="div" className="input-error" />
                <label htmlFor="bio" className="sub-title">About Your Company</label>
                <Field className="form-control" name="bio" type="text" as="textarea" rows={7}/>
                <ErrorMessage name="bio" component="div" className="input-error"/>
                {isRecruiter && <>
                    <label htmlFor="city" className="sub-title">Town/City</label>
                    <Field className="form-control" name="city" type="text"/>
                    <ErrorMessage name="city" component="div" className="input-error"/>
                    <label htmlFor="country" className="sub-title">Country</label>
                    <Field className="form-control" name="country" type="text"/>
                    <ErrorMessage name="country" component="div" className="input-error"/>
                </>}
                <label htmlFor="email" className="sub-title">Contact Email</label>
                <Field className="form-control" name="email" type="text"/>
                <ErrorMessage name="email" component="div" className="input-error"/>
                <label htmlFor="phone" className="sub-title">Contact Phone</label>
                <Field className="form-control" name="phone" type="text"/>
                <ErrorMessage name="phone" component="div" className="input-error"/>
                <label htmlFor="website" className="sub-title">Website</label>
                <Field className="form-control" name="website" type="text"/>
                <ErrorMessage name="website" component="div" className="input-error"/>
                <label htmlFor="linkedin" className="sub-title">LinkedIn</label>
                <Field className="form-control" name="linkedin" type="text"/>
                <ErrorMessage name="linkedin" component="div" className="input-error"/>
                <label htmlFor="facebook" className="sub-title">Facebook</label>
                <Field className="form-control" name="facebook" type="text"/>
                <ErrorMessage name="facebook" component="div" className="input-error"/>
                <label htmlFor="twitter" className="sub-title">Twitter</label>
                <Field className="form-control" name="twitter" type="text"/>
                <ErrorMessage name="twitter" component="div" className="input-error"/>
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit"
                        className="btn btn-secondary mt-4 ml-auto">{(loading) ? "Submitting" : (setup ? "Next" : "Save")}</button>
            </Form>
        </>}
    </Formik>
}

export const JOIN_FORM = props => {
    const DEFAULT_VALUES = {name: '', password: '', acceptTerms: false};
    const {changeData, handleSubmit, formError, initialValues = DEFAULT_VALUES, loading = false} = props;
    const isExisting = changeData?.existing;

    return <Formik
        initialValues={initialValues}
        validationSchema={isExisting ? ExistingJoinFormValidation : NewJoinFormValidation}
        onSubmit={handleSubmit}>
        {_ => <>
            <Form>
                {isExisting ? <>
                    <h4>{changeData.currentCompany && "Leave " + changeData.currentCompany + " to "}Join {changeData.newCompany}</h4>
                    <label htmlFor="password" className="sub-title">Confirm your Password</label>
                    <Field className="form-control" name="password" type="password"/>
                    <ErrorMessage name="password" component="div" className="input-error"/>
                </> : <>
                    <h4>Join {changeData.newCompany}</h4>
                    <label htmlFor="name" className="sub-title">Your Name</label>
                    <Field className="form-control" name="name" type="text"/>
                    <ErrorMessage name="name" component="div" className="input-error"/>
                    <label htmlFor="password" className="sub-title">Password</label>
                    <Field className="form-control" name="password" type="password"/>
                    <ErrorMessage name="password" component="div" className="input-error"/>
                    <div className="form-group form-check">
                        <Field type="checkbox" name="acceptTerms" className='form-check-input '/>
                        <label htmlFor="acceptTerms" className="form-check-label">I have read and understood the <Link to="/terms-conditions">terms and conditions</Link> and <Link to="/privacy-policy">privacy policy</Link></label>
                        <ErrorMessage name="acceptTerms" component="div" className="input-error terms" />
                    </div>
                </>}
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit"
                        className="btn btn-secondary mt-4 ml-auto">{(loading) ? "Joining" : "Join"}</button>
            </Form>
        </>}
    </Formik>
}
