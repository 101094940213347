import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {SEO} from "@bytehogs/design-system";
import {RegisterFormValidation} from "../../lib/formValidation";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Link, Redirect} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";

export const Register = props => {
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [formError, setFormError] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const {isAuthed, loginErr, registerSuccess, registerErr} = useSelector(_S => ({
        isAuthed: _S.AuthReducer.isAuthed,
        loginErr: _S.AuthReducer.loginErr,
        registerErr: _S.AuthReducer.registerErr,
        registerSuccess: _S.AuthReducer.registerSuccess,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {register, login} = bindActionCreators(AuthActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(isAuthed) setRedirect(true);
    }, []);

    useEffect(() => {
        if(loginErr) {
            setFormError(loginErr);
            setLoading(false);
        }
        else if(isAuthed) setRedirect(true);
    }, [loginErr, isAuthed]);

    useEffect(() => {
        if(registerErr && loading) {
            setFormError(registerErr);
            setLoading(false);
        }
        else if(registerSuccess && loading) {
            login(loginData.email, loginData.password);
        }
    }, [registerSuccess, registerErr]);

    const handleSubmit = async (values, {setSubmitting}) => {
        const {firstName, lastName, email, password} = values;
        const name = firstName.trim() + ' ' + lastName.trim();
        setLoading(true);
        setFormError(null);
        setLoginData({email, password});
        try {
            register(name, email, password);
        } catch(e) {
            setFormError(e.message);
            setLoading(false);
        } finally {
            setSubmitting(false);
        }
    }

    if(redirect) return <Redirect to="/create-account/type"/>
    return <div className="view auth register">
        <SEO title="Create a Right Recruit account" slug="/create-account" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Create an Account</h1>
                <Link to="/login" className="auth-link">Sign In Instead.</Link>
                <Formik
                    initialValues={{firstName: '', lastName: '', email: '', password: '', acceptTerms: false}}
                    validationSchema={RegisterFormValidation}
                    onSubmit={handleSubmit}>
                    {({isSubmitting}) => <>
                        <Form>
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="firstName" className="sub-title">First Name</label>
                                    <Field className="form-control" name="firstName" type="text"/>
                                    <ErrorMessage name="firstName" component="div"
                                                  className="input-error"/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="lastName" className="sub-title">Last Name</label>
                                    <Field className="form-control" name="lastName" type="text"/>
                                    <ErrorMessage name="lastName" component="div"
                                                  className="input-error"/>
                                </div>
                            </div>
                            <label htmlFor="email" className="sub-title">Email</label>
                            <Field className="form-control" name="email" type="email"/>
                            <ErrorMessage name="email" component="div"
                                          className="input-error"/>
                            <label htmlFor="password" className="sub-title">Password</label>
                            <Field className="form-control" name="password" type="password"/>
                            <ErrorMessage name="password" component="div"
                                          className="input-error"/>
                            <div className="form-group form-check">
                                <label  className="form-check-label">
                                    <Field type="checkbox" name="acceptTerms" className='form-check-input '/>
                                    I have read and understood the <Link to="/terms-conditions">terms and conditions</Link> and <Link to="/privacy-policy">privacy policy</Link></label>
                                <ErrorMessage name="acceptTerms" component="div" className="input-error terms" />
                            </div>
                            {formError && <div className="input-error">{formError}</div>}
                            <button type="submit"
                                    className="btn btn-secondary mt-4">{(isSubmitting || loading) ? "Creating" : "Create Account"}</button>
                        </Form>
                    </>}
                </Formik>
            </div>
        </div>
    </div>
}
