import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SEO} from "@bytehogs/design-system";
import {Link, Redirect} from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {CompanyActionCreators} from "../../redux/actions/company";
import {SelectFilters} from "../../components/filters/SelectFilters";
import {NewUserInput} from "../../components/team/NewUserInput";
import {TeamActionCreators} from "../../redux/actions/team";
import {NewUserForm} from "../../components/team/NewUserForm";

export const InviteUsers = props => {
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);

    }, []);

    const handleSuccess = () => {
        setRedirect('/account')
    }

    if(redirect) return <Redirect to={redirect}/>
    return <div className="view auth add-details">
        <SEO title="Invite Your Team | Right Recruit" slug="/create-account/invite-users" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Invite your Team</h1>
                <p className="mb-2">Invite your full team to benefit from RightRecruit!</p>
                <NewUserForm onSuccess={handleSuccess} setup/>
                {/*<NewUserInput setValues={setValues}/>
                {errors.invites && <>
                    {errors.invites.map(er => <div key={er.email} className="input-error">{er.email} - {er.message}</div>)}
                    {successes.map(ss => <div key={ss} className="text-success">{ss} - Successfully invited</div>)}
                </>}
                <button className="btn btn-secondary" onClick={handleSubmit} disabled={loading || values.length === 0}>{loading ? "Saving" : "Next"}</button>*/}
            </div>
        </div>
    </div>
}
