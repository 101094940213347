import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SEO} from "@bytehogs/design-system";
import {Link, Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";

export const AccountType = props => {
    const [redirect, setRedirect] = useState(null);
    const {userDetails} = useSelector(_S => ({
        userDetails: _S.AuthReducer.userDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getCurrentUser} = bindActionCreators(AuthActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(JSON.stringify(userDetails) === '{}') getCurrentUser();
    }, []);

    useEffect(() => {
        if(userDetails?.linkId) setRedirect(true);
    }, [userDetails]);

    if(redirect) return <Redirect to="/account"/>
    return <div className="view auth account-type">
        <SEO title="How will you use Right Recruit" slug="/login" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>How will you be using RightRecruit?</h1>
                <Link to="/jobs" className="btn btn-block btn-primary">I am job seeker</Link>
                <Link to="/create-account/company" className="btn btn-block btn-secondary">I am a company looking to hire</Link>
                <Link to="/create-account/recruiter" className="btn btn-block btn-red">I am a recruiter</Link>
            </div>
        </div>
    </div>
}
