import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SEO} from "@bytehogs/design-system";
import {Redirect, useLocation} from "react-router-dom";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {CompanyActionCreators} from "../../redux/actions/company";
import {COMPANY_FORM} from "../../lib/forms";

export const CreateCompany = props => {
    const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [isRecruiter, setRecruiter] = useState(null);
    const {pathname} = useLocation();
    const {userDetails, companyErr, companySuccess} = useSelector(_S => ({
        userDetails: _S.AuthReducer.userDetails,
        companyErr: _S.CompanyReducer.companyErr,
        companySuccess: _S.CompanyReducer.companySuccess,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getCurrentUser} = bindActionCreators(AuthActionCreators, dispatch);
    const {updateCompany} = bindActionCreators(CompanyActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(JSON.stringify(userDetails) === '{}') getCurrentUser();
        if(pathname.includes('/recruiter')) setRecruiter(true);
    }, []);

    useEffect(() => {
        //if(userDetails?.linkId && !loading) setRedirect('/account');
    }, [userDetails]);

    useEffect(() => {
        if(companyErr) {
            setFormError(companyErr);
            setLoading(false);
        } else if(companySuccess) {
            setRedirect(isRecruiter ? '/create-account/details' : '/create-account/invite-team');
        }
    }, [companyErr, companySuccess]);

    const handleSubmit = async (values) => {
        const {name, bio, city, country, email, phone, website, linkedin, facebook, twitter, logo} = values;
        setLoading(true);
        setFormError(null);
        try {
            const theData = {isRecruiter: isRecruiter ? 1 : 0, name, bio, city, country, email, phone, website, linkedin, facebook, twitter, logo}
            updateCompany(theData);
        } catch(e) {
            setFormError(e.message);
            setLoading(false);
        }
    };

    if(redirect) return <Redirect to={redirect}/>
    const TERM = isRecruiter ? "recruiter" : "company";
    return <div className="view auth create-company">
        <SEO title={"Create a " + TERM + " account for Right Recruit"} slug={pathname} description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Create a {TERM} account</h1>
                <COMPANY_FORM handleSubmit={handleSubmit} loading={loading} formError={formError} isRecruiter={isRecruiter} setup/>
            </div>
        </div>
    </div>
}
