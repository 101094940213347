import {CompanyActionTypes} from "../../types";
import {BytehogsAPI} from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";
import axios from "axios";

const SERVICE = "rr-be";

export const CompanyActionCreators = {
    getFilters: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.GET_FILTERS_REQ,
        });
        try {
            let payload = {operation: "getFilters"};
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: CompanyActionTypes.GET_FILTERS_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.GET_FILTERS_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    createFilter: (type, name) => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.CREATE_FILTERS_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {type, name, sessionToken, operation: "createFilter"}
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: CompanyActionTypes.CREATE_FILTERS_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.CREATE_FILTERS_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    updateCompany: data => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.UPDATE_COM_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {...data, sessionToken, operation: "update"}
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: CompanyActionTypes.UPDATE_COM_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.UPDATE_COM_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    uploadLogo: (image, name, fileType, fileExt) => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.UPLOAD_LOGO_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {image, name, fileType, fileExt, sessionToken, operation: "uploadLogo"}
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            await axios.put(response.data.uploadURL, image, {
                headers: {
                    'Content-Type': fileType
                }
            })
            dispatch({
                type: CompanyActionTypes.UPLOAD_LOGO_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.UPLOAD_LOGO_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    getCompany: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.GET_COM_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getDetails"};
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: CompanyActionTypes.GET_COM_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.GET_COM_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    updateFilters: filters => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.UPDATE_FILTERS_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {filters, sessionToken, operation: "updateFilters"}
            const response = await BytehogsAPI(SERVICE, "company", payload, STAGE);
            dispatch({
                type: CompanyActionTypes.UPDATE_FILTERS_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.UPDATE_FILTERS_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
};
