import React, {useEffect, useState} from "react";
import {SEO} from '@bytehogs/design-system';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {SearchBar} from "../../components/SearchBar";
import {bindActionCreators} from "redux";
import {RecruitersActionCreators} from "../../redux/actions/recruiters";
import {RecruiterRatingSummary, RecruiterReview} from "../../components/recruiters";

export const RecruiterReviews = props => {
    const [initLoading, setInitLoading] = useState(0);
    const [recruiter, setRecruiter] = useState(null);
    const { id } = useParams();
    const {recDetails, recruiterErr} = useSelector(_S => ({
        baseFilters: _S.CompanyReducer.filters,
        recruiterErr: _S.RecruitersReducer.recruiterErr,
        recDetails: _S.RecruitersReducer.recDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getRecruiterDetails} = bindActionCreators(RecruitersActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(!recDetails[id]) {
            setInitLoading(1);
            getRecruiterDetails(id);
        } else {
            setInitLoading(2);
            setRecruiter(recDetails[id]);
        }
    }, []);

    useEffect(() => {
        if(initLoading === 1) {
            if(recDetails[id]) setRecruiter(recDetails[id]);
            setInitLoading(2)
        }
    }, [recDetails]);

    useEffect(() => {
        if(initLoading === 1 && recruiterErr) setInitLoading(2);
    }, [recruiterErr]);

    const LOADING_CONTENT = <div className="col-12">Getting Reviews</div>;

    return <div className="view recruiter reviews">
        <SEO title={(recruiter ? recruiter.name : "Recruiter") + " Reviews | Right Recruit"} slug="" description="" image="" locale="en_GB" type="website" />
        <SearchBar type="recruiters"/>
        <div className="container">
            <div className="row">
                {{  0: LOADING_CONTENT,
                    1: LOADING_CONTENT,
                    2: <>
                        {!recruiter ? <div className="col-12">Recruiter Not Found</div> : <>
                            <div className="col-12">
                                <h1>Showing All Reviews for {recruiter.name}</h1>
                            </div>
                            <div className="col-md-6">
                                <ReviewsSection testimonials={recruiter.testimonials} reviewToken={recruiter.testimonialToken} type='company' rating={recruiter.avgCompanyRating}/>
                            </div>
                            <div className="col-md-6">
                                <ReviewsSection testimonials={recruiter.testimonials} reviewToken={recruiter.testimonialToken} type='candidate' rating={recruiter.avgCandidateRating}/>
                            </div>
                            </>}
                    </>
                   }[initLoading]}
            </div>
        </div>
    </div>
}

const ReviewsSection = props => {
    const {testimonials, type, rating, reviewToken} = props;
    let ratingCountByStar = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
    const activeTestimonials = testimonials.filter(T => {
        if(T.type === type) {
            ratingCountByStar[Math.floor(T.rating)]++;
            return T;
        }
    });
    const totalRatings = Object.values(ratingCountByStar).reduce((a,b) => a + b);
    const reviewCount = activeTestimonials.length;
    const isCompany = type === 'company';

    return <div className="reviews-section-card">
        <div className="row">
            <div className="col-md-6">
                <span className="sub-title">{type} Reviews</span>
                <RecruiterRatingSummary
                    reviewCount={reviewCount}
                    reviewToken={reviewToken}
                    isCompany={isCompany}
                    totalRatings={totalRatings}
                    ratingCounts={ratingCountByStar}
                    rating={rating}/>
            </div>
            <div className="col-12">
                {activeTestimonials.map(T => <RecruiterReview key={T.id} {...T} isCompany={isCompany}/>)}
            </div>
        </div>
    </div>
}
