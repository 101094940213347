import {RecruitersActionTypes} from "../../types";

export const initialState = {
    loading: false,
    recruiterErr: null,
    totalRecruiters: 0,
    recruiters: [],
    filters: [],
    recDetails: {},
    reviewTokenValid: 0,
    reviewErr: null,
    reviewSuccess: false,
    contactSuccess: false,
    reviewRecId: null,
};

export const RecruitersReducer = (state = initialState, action) => {
    const { type, payload, reset, error } = action;
    switch (type) {
        case RecruitersActionTypes.SEARCH_RECS_REQ: {
            return {
                ...state,
                loading: true,
                recruiterErr: null,
            };
        }
        case RecruitersActionTypes.SEARCH_RECS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    recruiterErr: error,
                    recruiters: [],
                    totalRecruiters: 0,
                    filters: []
                };
            }
            return {
                ...state,
                loading: false,
                recruiterErr: null,
                recruiters: reset ? payload.recruiters : [...state.recruiters, ...payload.recruiters],
                totalRecruiters: payload.total_records,
                filters: payload.filters || state.filters
            };
        }
        case RecruitersActionTypes.GET_RDETAILS_REQ: {
            return {
                ...state,
                loading: true,
                recruiterErr: null,
            };
        }
        case RecruitersActionTypes.GET_RDETAILS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    recruiterErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                recruiterErr: null,
                recDetails: {
                    [payload.recruiter.id]: payload.recruiter
                }
            };
        }
        case RecruitersActionTypes.CONTACT_REC_REQ: {
            return {
                ...state,
                loading: true,
                recruiterErr: null,
                contactSuccess: false
            };
        }
        case RecruitersActionTypes.CONTACT_REC_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    recruiterErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                recruiterErr: null,
                contactSuccess: true
            };
        }
        case RecruitersActionTypes.VERIFY_TOKEN_REQ: {
            return {
                ...state,
                loading: true,
                recruiterErr: null,
                reviewTokenValid: 0,
                reviewRecName: null,
                reviewRecId: null,
            };
        }
        case RecruitersActionTypes.VERIFY_TOKEN_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    recruiterErr: error,
                    reviewTokenValid: 2
                };
            }
            return {
                ...state,
                loading: false,
                recruiterErr: null,
                reviewTokenValid: 1,
                reviewRecName: payload.name,
                reviewRecId: payload.id
            };
        }
        case RecruitersActionTypes.LEAVE_REVIEW_REQ: {
            return {
                ...state,
                loading: true,
                reviewErr: null
            };
        }
        case RecruitersActionTypes.LEAVE_REVIEW_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    reviewErr: error
                };
            }
            return {
                ...state,
                loading: false,
                reviewErr: null,
                reviewSuccess: true
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
