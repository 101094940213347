import React from "react";
import {RecruiterCard} from "./RecruiterCard";

export const RecruitersList = props => {
    const {recruiters, currentPage, limitPerPage = 20, totalResults, loading} = props;
    const results = totalResults || recruiters.length;

    return <div className="recruiters-list">
        <h2 className="title">{loading ? "Searching" : <>{results || "No"} Recruiter{(!results || results > 1) && "s"} Found</>}</h2>
        <hr/>
        {!loading ? recruiters.map((R, i) => {
            const I = i + 1;
            if(I > (currentPage * (limitPerPage)) || I <= ((currentPage - 1) * (limitPerPage))) return null;
            return <RecruiterCard key={R.id} {...R}/>
        }): <div></div>}
    </div>
};
