import React, {useEffect, useState} from "react";
import {SEO} from "@bytehogs/design-system";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {TeamActionCreators} from "../../redux/actions/team";
import {useParams, Redirect} from "react-router-dom";
import {JOIN_FORM} from "../../lib/forms";

export const Join = props => {
    const [status, setStatus] = useState(0);//0 checking, 1 valid token, 2 invalid token, 3 submitting
    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const { token } = useParams();
    const {teamErr, inviteTokenValid, joinChangeData, inviteSuccess} = useSelector(_S => ({
        teamErr: _S.TeamReducer.teamErr,
        inviteTokenValid: _S.TeamReducer.inviteTokenValid,
        joinChangeData: _S.TeamReducer.joinChangeData,
        inviteSuccess: _S.TeamReducer.inviteSuccess,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {checkToken, acceptInvite} = bindActionCreators(TeamActionCreators, dispatch);

    useEffect(() => {
        checkToken(token);
    }, []);

    useEffect(() => {
        if(status === 0 && teamErr) {
            setStatus(2);
        } else if(status === 0 && inviteTokenValid) {
            setStatus(1);
        } else if(status === 3 && teamErr) {
            setStatus(1);
            setError(teamErr)
        } else if(status === 3 && inviteSuccess) {
            setStatus(1);
            setRedirect('/login');
        }
    }, [teamErr, inviteTokenValid, inviteSuccess]);

    const handleSubmit = values => {
        if(status !== 3) {
            setStatus(3);
            acceptInvite({...values, token});
        }
    }

    const FORM = <JOIN_FORM changeData={joinChangeData} handleSubmit={handleSubmit} formError={error} loading={status === 3}/>;
    if(redirect) return <Redirect to={redirect}/>
    return <div className="view account join">
        <SEO title="Join | Right Recruit" slug="/join" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="account-card">
                {{
                    0: <div>Checking Token</div>,
                    1: FORM,
                    2: <div>Invalid Token</div>,
                    3: FORM
                }[status]}
            </div>
        </div>
    </div>
}
