import React from "react";
import {Link} from "react-router-dom";

export const Footer = props => {
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <h4>Company</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/logout">Logout</Link></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <h4>Legal</h4>
                    <ul>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-conditions">Terms &amp; Conditions</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
}
