export const AuthActionTypes = {
    AUTH_STATUS: "AUTH_STATUS",
    REGISTER_REQ: "REGISTER_REQ",
    REGISTER_RES: "REGISTER_RES",
    LOGIN_REQ: "LOGIN_REQ",
    LOGIN_RES: "LOGIN_RES",
    FORGOT_PW_REQ: "FORGOT_PW_REQ",
    FORGOT_PW_RES: "FORGOT_PW_RES",
    CHECK_PW_REQ: "CHECK_PW_REQ",
    CHECK_PW_RES: "CHECK_PW_RES",
    SET_PW_REQ: "SET_PW_REQ",
    SET_PW_RES: "SET_PW_RES",
    GET_USER_REQ: "GET_USER_REQ",
    GET_USER_RES: "GET_USER_RES",
    UPDATE_USER_REQ: "UPDATE_USER_REQ",
    UPDATE_USER_RES: "UPDATE_USER_RES",
}

export const RecruitersActionTypes = {
    SEARCH_RECS_REQ: "SEARCH_RECS_REQ",
    SEARCH_RECS_RES: "SEARCH_RECS_RES",
    GET_RDETAILS_REQ: "GET_RDETAILS_REQ",
    GET_RDETAILS_RES: "GET_RDETAILS_RES",
    VERIFY_TOKEN_REQ: "VERIFY_TOKEN_REQ",
    VERIFY_TOKEN_RES: "VERIFY_TOKEN_RES",
    LEAVE_REVIEW_REQ: "LEAVE_REVIEW_REQ",
    LEAVE_REVIEW_RES: "LEAVE_REVIEW_RES",
    CONTACT_REC_REQ: "CONTACT_REC_REQ",
    CONTACT_REC_RES: "CONTACT_REC_RES",
}

export const CompanyActionTypes = {
    GET_FILTERS_REQ: "GET_FILTERS_REQ",
    GET_FILTERS_RES: "GET_FILTERS_RES",
    CREATE_FILTERS_REQ: "CREATE_FILTERS_REQ",
    CREATE_FILTERS_RES: "CREATE_FILTERS_RES",
    GET_COM_REQ: "GET_COM_REQ",
    GET_COM_RES: "GET_COM_RES",
    UPDATE_COM_REQ: "UPDATE_COM_REQ",
    UPDATE_COM_RES: "UPDATE_COM_RES",
    UPLOAD_LOGO_REQ: "UPLOAD_LOGO_REQ",
    UPLOAD_LOGO_RES: "UPLOAD_LOGO_RES",
    UPDATE_FILTERS_REQ: "UPDATE_FILTERS_REQ",
    UPDATE_FILTERS_RES: "UPDATE_FILTERS_RES",
}

export const TeamActionTypes = {
    GET_TEAM_REQ: "GET_TEAM_REQ",
    GET_TEAM_RES: "GET_TEAM_RES",
    REMOVE_USER_REQ: "REMOVE_USER_REQ",
    REMOVE_USER_RES: "REMOVE_USER_RES",
    INVITE_USER_REQ: "INVITE_USER_REQ",
    INVITE_USER_RES: "INVITE_USER_RES",
    VERIFY_TOKEN_REQ: "VERIFY_TOKEN_REQ",
    VERIFY_TOKEN_RES: "VERIFY_TOKEN_RES",
    ACCEPT_INVITE_REQ: "ACCEPT_INVITE_REQ",
    ACCEPT_INVITE_RES: "ACCEPT_INVITE_RES",
}
