import {TeamActionTypes} from "../../types";
import {BytehogsAPI} from "@bytehogs/design-system";
import {STAGE} from "../../../getStage";

const SERVICE = "rr-be";

export const TeamActionCreators = {
    getAll: () => async (dispatch) => {
        dispatch({
            type: TeamActionTypes.GET_TEAM_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, operation: "getAll"};
            const response = await BytehogsAPI(SERVICE, "users", payload, STAGE);
            dispatch({
                type: TeamActionTypes.GET_TEAM_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: TeamActionTypes.GET_TEAM_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    remove: userId => async (dispatch) => {
        dispatch({
            type: TeamActionTypes.REMOVE_USER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, operation: "remove", userId};
            const response = await BytehogsAPI(SERVICE, "users", payload, STAGE);
            dispatch({
                type: TeamActionTypes.REMOVE_USER_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: TeamActionTypes.REMOVE_USER_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    invite: users => async (dispatch) => {
        dispatch({
            type: TeamActionTypes.INVITE_USER_REQ,
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, operation: "invite", users};
            const response = await BytehogsAPI(SERVICE, "users", payload, STAGE);
            dispatch({
                type: TeamActionTypes.INVITE_USER_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: TeamActionTypes.INVITE_USER_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    checkToken: token => async (dispatch) => {
        dispatch({
            type: TeamActionTypes.VERIFY_TOKEN_REQ,
        });
        try {
            let payload = {operation: "checkToken", token};
            const response = await BytehogsAPI(SERVICE, "users", payload, STAGE);
            dispatch({
                type: TeamActionTypes.VERIFY_TOKEN_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: TeamActionTypes.VERIFY_TOKEN_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
    acceptInvite: data => async (dispatch) => {
        dispatch({
            type: TeamActionTypes.ACCEPT_INVITE_REQ,
        });
        try {
            let payload = {...data, operation: "acceptInvite"};
            const response = await BytehogsAPI(SERVICE, "users", payload, STAGE);
            dispatch({
                type: TeamActionTypes.ACCEPT_INVITE_RES,
                error: null,
                payload: response.data,
            });
        } catch (e) {
            dispatch({
                type: TeamActionTypes.ACCEPT_INVITE_RES,
                error: e?.data?.message || e.message || "Something went wrong",
            });
        }
    },
};
