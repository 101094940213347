import React from "react";
import {AccountNavigation} from "../../components/account/AccountNavigation";
import {SEO} from "@bytehogs/design-system";
import {RecruiterAddFilters} from "../../components/recruiters/RecruiterAddFilters";

export const RecruiterFilters = props => {

    return <div className="view account filters">
        <SEO title="Manage Your Recruiter Filters | Right Recruit" slug="/account/company/filters" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <AccountNavigation/>
            <div className="account-card">
                <h4>Manage Filters</h4>
                <RecruiterAddFilters onSuccess={() => {}}/>
            </div>
        </div>
    </div>
}
