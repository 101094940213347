import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, BrowserRouter, Redirect, Switch} from "react-router-dom";
import {analytics, AnalyticsTracker, BHGToast} from '@bytehogs/design-system';
import {ToastProvider} from 'react-toast-notifications';
import {CookieMessage} from "./CookieMessage";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {SearchRecruiters, RecruiterDetails, RecruiterReviews, RecruiterLeaveReview} from "../../pages/recruiters";
import {
    Login,
    ForgotPassword,
    Register,
    AccountType,
    CreateCompany,
    RecruiterAddDetails,
    InviteUsers, SetPassword
} from "../../pages/auth";
import {AuthActionCreators} from "../../redux/actions/auth";
import {bindActionCreators} from "redux";
import {CompanyDetails, Join, ManageTeam, PersonalDetails, RecruiterFilters} from "../../pages/account";

const ProtectedRoute = ({ isVerified, li, ...props }) => {
    return isVerified ? (
        <Route {...props} />
    ) : (
        <Redirect to={li ? "/" : "/login?return_url=" + props.path} />
    );
};

class Routing extends Component {
    constructor(props) {
        super(props);
        analytics.initialize('', {});

        this.state = { canRender: false };
    }

    componentWillMount() {
        const { authActions } = this.props;
        authActions.checkToken().then(() => authActions.getCurrentUser()).finally(() => this.setState({canRender: true}))
    }

    render() {
        const { canRender } = this.state;
        const { isAuthed, authActions } = this.props;
        if (canRender) {
            return (
                <BrowserRouter>
                    <ToastProvider components={{ Toast: BHGToast }}>
                        <Header />
                        <Switch>
                            {/*<Route exact path="/" component={()=><div>Hello World</div>}/>*/}
                            <Route exact path="/recruiters" component={AnalyticsTracker(SearchRecruiters)}/>
                            <Route exact path="/recruiters/:id" component={AnalyticsTracker(RecruiterDetails)}/>
                            <Route exact path="/recruiters/:id/reviews" component={AnalyticsTracker(RecruiterReviews)}/>
                            <Route exact path="/recruiters/leave-review/:token" component={AnalyticsTracker(RecruiterLeaveReview)}/>
                            <Route exact path="/recruiters/leave-review/:token/:recId" component={AnalyticsTracker(RecruiterLeaveReview)}/>

                            <Route exact path="/login" component={AnalyticsTracker(Login)}/>
                            <Route exact path="/create-account" component={AnalyticsTracker(Register)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/create-account/type" component={AnalyticsTracker(AccountType)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/create-account/company" component={AnalyticsTracker(CreateCompany)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/create-account/recruiter" component={AnalyticsTracker(CreateCompany)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/create-account/details" component={AnalyticsTracker(RecruiterAddDetails)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/create-account/invite-users" component={AnalyticsTracker(InviteUsers)}/>
                            <Route exact path="/logout" component={() => {authActions.logout();return  <Redirect to="/" />}}/>
                            <ProtectedRoute li isVerified={!isAuthed} exact path="/forgot-password" component={AnalyticsTracker(ForgotPassword)}/>
                            <ProtectedRoute li isVerified={!isAuthed} exact path="/set-password/:token" component={AnalyticsTracker(SetPassword)}/>

                            <ProtectedRoute isVerified={isAuthed} exact path="/account" component={AnalyticsTracker(PersonalDetails)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/account/company" component={AnalyticsTracker(CompanyDetails)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/account/company/filters" component={AnalyticsTracker(RecruiterFilters)}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/account/team" component={AnalyticsTracker(ManageTeam)}/>

                            <Route exact path="/join/:token" component={AnalyticsTracker(Join)}/>

                            <Redirect to="/recruiters" />
                        </Switch>
                        <Footer />
                        <CookieMessage/>
                    </ToastProvider>
                </BrowserRouter>
            );
        }
        return <div className="container">Loading...</div>;
    }
}


const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
