import {TeamActionTypes} from "../../types";

export const initialState = {
    loading: false,
    team: [],
    teamErr: null,
    teamSuccess: null,
    inviteResults: null,
    inviteTokenValid: null,
    joinChangeData: null,
    inviteSuccess: null
};

export const TeamReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case TeamActionTypes.GET_TEAM_REQ: {
            return {
                ...state,
                loading: true,
                teamErr: null,
            };
        }
        case TeamActionTypes.GET_TEAM_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    teamErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                teamErr: null,
                team: [...payload.users, ...payload.invited]
            };
        }
        case TeamActionTypes.REMOVE_USER_REQ: {
            return {
                ...state,
                loading: true,
                teamErr: null,
                teamSuccess: null
            };
        }
        case TeamActionTypes.REMOVE_USER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    teamErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                teamErr: null,
                teamSuccess: true
            };
        }
        case TeamActionTypes.INVITE_USER_REQ: {
            return {
                ...state,
                loading: true,
                teamErr: null,
                inviteResults: null
            };
        }
        case TeamActionTypes.INVITE_USER_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    teamErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                teamErr: null,
                inviteResults: payload.responses
            };
        }
        case TeamActionTypes.VERIFY_TOKEN_REQ: {
            return {
                ...state,
                loading: true,
                teamErr: null,
                inviteTokenValid: null
            };
        }
        case TeamActionTypes.VERIFY_TOKEN_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    teamErr: error,
                    inviteTokenValid: false
                };
            }
            return {
                ...state,
                loading: false,
                teamErr: null,
                inviteTokenValid: true,
                joinChangeData: payload.changeData
            };
        }
        case TeamActionTypes.ACCEPT_INVITE_REQ: {
            return {
                ...state,
                loading: true,
                teamErr: null,
                inviteSuccess: null
            };
        }
        case TeamActionTypes.ACCEPT_INVITE_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    teamErr: error,
                };
            }
            return {
                ...state,
                loading: false,
                teamErr: null,
                inviteSuccess: true
            };
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
