import {RatingContainer} from "../RatingContainer";
import React from "react";

export const RecruiterReview = props => {
    const {title, comment, rating, isCompany} = props;
    return <div className="recruiter-review">
        <RatingContainer rating={rating} hideRating company={isCompany}/>
        <span className="review-title">{title}</span>
        <p>{comment}</p>
    </div>
}
