import React from "react";

export const RecruiterContactCard = props => {
    const {recruiter} = props;
    const {city, country, email, phone, website} = recruiter;
    return <div className="recruiter-contact-card">
        <span className="sub-title">Contact Information</span>
        <span className="item">{city}{country && ', ' + country}</span>
        <span className="item">{phone}</span>
        {website && <span className="item"><a href={website} target="_blank" rel="noopener noreferrer">{website}</a></span>}
        {email && <span className="item"><a href={'mailto:'+email}>{email}</a></span>}
    </div>
};
