import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {Logo} from "./Logo";
import {shallowEqual, useSelector} from "react-redux";

export const Header = props => {
    const [isOpen, setOpen] = useState(false);
    const {isAuthed} = useSelector(_S => ({
        isAuthed: _S.AuthReducer.isAuthed
    }), shallowEqual);
    return (
        <header className={"main-header"}>
            <div className="container">
                <div className="left">
                    <div className="brand">
                        <Link to="/" className="desktop">
                            <Logo />
                        </Link>
                        <Link to="/" className="mobile">
                            <Logo mobile />
                        </Link>
                    </div>
                </div>
                <nav className={isOpen ? "open" : ""}>
                    <NavLink to="/recruiters" onClick={() => setOpen(false)}>Recruiters</NavLink>

                    {/*<NavLink to="/jobs">Jobs</NavLink>*/}
                    {!isAuthed && <NavLink to="/login" onClick={() => setOpen(false)}>Login</NavLink>}
                    {isAuthed && <NavLink to="/account" onClick={() => setOpen(false)}>Account</NavLink>}
                    {isAuthed && <NavLink to="/logout" onClick={() => setOpen(false)}><span className="far fa-sign-out desktop"/><span className="mobile">Logout</span></NavLink>}
                </nav>
                <div className="header-actions mobile">
                    <i className={"far " + (isOpen ? "fa-times" : "fa-bars")} onClick={() => setOpen(!isOpen)}/>
                </div>
            </div>
        </header>
    );
}
