import React, {useEffect, useState} from "react";
import {AccountNavigation} from "../../components/account/AccountNavigation";
import {SEO} from "@bytehogs/design-system";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {TeamActionCreators} from "../../redux/actions/team";
import {NewUserForm} from "../../components/team/NewUserForm";

const roles = {1: "Admin", 2: "Member"};

export const ManageTeam = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {teamErr, teamSuccess, userDetails, team} = useSelector(_S => ({
        userDetails: _S.AuthReducer.userDetails,
        companyDetails: _S.CompanyReducer.companyDetails,
        team: _S.TeamReducer.team,
        teamErr: _S.TeamReducer.teamErr,
        teamSuccess: _S.TeamReducer.teamSuccess,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getAll, remove} = bindActionCreators(TeamActionCreators, dispatch);

    useEffect(() => {
        getAll();
    },[]);

    const removeTeamMember = id => {
        if(!loading) {
            setLoading(id);
            remove(id);
        }
    };

    useEffect(() => {
        if(teamErr && loading) {
            setError(teamErr);
            setLoading(false);
        } else if(teamSuccess && loading) {
            setLoading(false);
            getAll();
        }
    }, [teamErr, teamSuccess]);

    return <div className="view account team">
        <SEO title="Manage Your Team | Right Recruit" slug="/account/team" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <AccountNavigation/>
            <div className="account-card">
                <h4>Manage Team</h4>
                <table className="table table-striped team-table">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {team.map(TM => {
                            const isCurrent = TM.id === userDetails.id;
                            return <tr className="team-member" key={TM.id}>
                                <td>{TM.name}</td>
                                <td>{TM.email}</td>
                                <td>{roles[TM.roleId]}</td>
                                <td>{TM.invited === 1 ? <span>Invited</span> : isCurrent ? null : <span onClick={() => removeTeamMember(TM.id)}>{loading === TM.id ?  <i className="fas fa-spinner fa-spin"/> : <i className="fas fa-trash"/> }</span>}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            <div className="account-card">
                <h4>Invite Team Members</h4>
                <NewUserForm onSuccess={getAll}/>
            </div>
        </div>
    </div>
}
