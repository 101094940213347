import React from 'react';
import {RatingContainer} from "../RatingContainer";
import {Link} from "react-router-dom";
const ratingStarBlue = "https://client-upload.bytehogs.com/rr/icons/star-blue.svg";
const ratingStarGreen = "https://client-upload.bytehogs.com/rr/icons/star-green.svg";

export const RecruiterRatingSummary = props => {
    const {rating, isCompany, reviewCount, ratingCounts, totalRatings, reviewToken} = props;
    const ratingType = isCompany ? 'company' : 'candidate';
    return <div className="recruiter-rating-summary">
        <div className="rating-row">
            <RatingContainer rating={rating} hideRating company={isCompany}/>
            <span>{Number(rating).toFixed(1).replace('.0', '')} out of 5</span>
        </div>
        <span className="review-count">{reviewCount || 'No'} {ratingType} review{!reviewCount || reviewCount > 1 ? 's' : ''}</span>
        <div className="rating-breakdown">
            <RatingItem starRating={5} ratingCounts={ratingCounts} totalRatings={totalRatings} type={ratingType}/>
            <RatingItem starRating={4} ratingCounts={ratingCounts} totalRatings={totalRatings} type={ratingType}/>
            <RatingItem starRating={3} ratingCounts={ratingCounts} totalRatings={totalRatings} type={ratingType}/>
            <RatingItem starRating={2} ratingCounts={ratingCounts} totalRatings={totalRatings} type={ratingType}/>
            <RatingItem starRating={1} ratingCounts={ratingCounts} totalRatings={totalRatings} type={ratingType}/>
        </div>
        <Link to={'/recruiters/leave-review/' + reviewToken + '?type=' + (isCompany ? 'company' : 'candidate')} className={(isCompany ? "btn-secondary" : "btn-primary") + " btn btn-block"}>Leave Testimonial</Link>
    </div>
}

const RatingItem = props => {
    const {starRating, ratingCounts, totalRatings, type} = props;
    return <div className="row no-gutters rating-item">
        <div className="col-3">
            {starRating}
            <img src={type === 'company' ? ratingStarGreen : ratingStarBlue} className="rating-star"/>
        </div>
        <div className="col">
            <RatingProgress count={ratingCounts[starRating]} total={totalRatings} type={type}/>
        </div>
    </div>
}

const RatingProgress = props => {
    const {count, total, type} = props;
    const value = (count/total) * 100 || 0;
    return <div className={"progress rating-progress " + type}>
        <div className="progress-bar" role="progressbar" title={count + ' reviews'} style={{width: value + "%"}} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
    </div>
}
