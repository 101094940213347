import React, {useEffect, useState} from "react";
import {SEO} from '@bytehogs/design-system';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import {SearchBar} from "../../components/SearchBar";
import {bindActionCreators} from "redux";
import {RecruitersActionCreators} from "../../redux/actions/recruiters";
import {ClickableRatingContainer} from "../../components/RatingContainer";
import {ReviewFormValidation} from "../../lib/formValidation";

export const RecruiterLeaveReview = props => {
    const [initLoading, setInitLoading] = useState(0);
    const [formStatus, setFormStatus] = useState(0); //0 start, 1 loading, 2 error, 3 success, 4 already submitted
    const [formError, setFormError] = useState(null);
    const { token, recId } = useParams();
    const { search } = useLocation();
    const theType = new URLSearchParams(search).get("type") || "company";

    const {reviewTokenValid, reviewRecName, reviewRecId, recruiterErr, reviewErr, reviewSuccess, userDetails} = useSelector(_S => ({
        recruiterErr: _S.RecruitersReducer.recruiterErr,
        reviewTokenValid: _S.RecruitersReducer.reviewTokenValid,
        reviewRecName: _S.RecruitersReducer.reviewRecName,
        reviewRecId: _S.RecruitersReducer.reviewRecId,
        reviewSuccess: _S.RecruitersReducer.reviewSuccess,
        reviewErr: _S.RecruitersReducer.reviewErr,
        userDetails: _S.AuthReducer.userDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getRecruiterDetails, verifyTestimonialToken, leaveTestimonial} = bindActionCreators(RecruitersActionCreators, dispatch);
    const userId = userDetails?.id;

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        setInitLoading(1);
        let storage = localStorage.getItem('reviewRecord') || '{}';
        storage = storage = JSON.parse(storage);
        if(storage && storage[token] && storage[token] > (new Date().getTime() - 2419200000)) {
            setInitLoading(2);
            setFormStatus(4);
        }
        verifyTestimonialToken(token, recId);
    }, []);

    useEffect(() => {
        if(initLoading === 1) setInitLoading(2);
    }, [reviewTokenValid]);

    useEffect(() => {
        if(initLoading === 1 && recruiterErr) setInitLoading(2);
    }, [recruiterErr]);

    useEffect(() => {
        if(formStatus === 1 && reviewErr) {
            setFormStatus(2);
            setFormError(reviewErr);
        }
    }, [reviewErr]);

    useEffect(() => {
        if(formStatus === 1 && reviewSuccess) {
            setFormStatus(3);
            let storage = localStorage.getItem('reviewRecord') || '{}';
            storage = JSON.parse(storage);
            storage[token] = new Date().getTime();
            localStorage.setItem('reviewRecord', JSON.stringify(storage));
            getRecruiterDetails(reviewRecId);
        }
    }, [reviewSuccess]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const {title, comment, rating, type, email} = values;
            setFormStatus(1);
            setFormError(null);
            await leaveTestimonial(token, recId, title, comment, rating, type, userId, email);
        } catch(e) {
            console.log("Caught in submit", e);
            setFormStatus(2);
            setFormError(e.message);
        } finally {
            setSubmitting(false);
        }
    }

    const LOADING_CONTENT = <div className="col-12">Loading Recruiter Review</div>;

    const FORM = <Formik
        initialValues={{type: theType, rating: 0, title: '', comment: '', email: '', userId}}
        validationSchema={ReviewFormValidation}
        onSubmit={handleSubmit}>
        {({isSubmitting, values, setFieldValue}) => <>
            <Form>
                {!userId && <>
                    <label htmlFor="email" className="sub-title">Email</label>
                    <Field className="form-control" name="email" type="text"/>
                    <ErrorMessage name="email" component="div"
                                  className="input-error"/>
                </>}
                <label htmlFor="type" className="sub-title">Type</label>
                <div role="group" aria-labelledby="type-group">
                    <label>
                        <Field type="radio" name="type" value="company"/>
                        Company
                    </label>
                    <label>
                        <Field type="radio" name="type" value="candidate"/>
                        Candidate
                    </label>
                </div>
                <ErrorMessage name="type" component="div"
                              className="input-error"/>
                <label htmlFor="rating" className="sub-title">Rating</label>
                <ClickableRatingContainer
                    rating={values.rating}
                    company={values.type === 'company'}
                    onClick={R => setFieldValue('rating', R)}/>
                <ErrorMessage name="rating" component="div"
                              className="input-error"/>
                <label htmlFor="title" className="sub-title">Title</label>
                <Field className="form-control" name="title" type="text"/>
                <ErrorMessage name="title" component="div"
                              className="input-error"/>
                <label htmlFor="comment" className="sub-title">Comment</label>
                <Field className="form-control" name="comment" type="text"
                       as="textarea" rows={4}/>
                <ErrorMessage name="comment" component="div"
                              className="input-error"/>
                {formError && <div className="input-error">{formError}</div>}
                <button type="submit"
                        className="btn btn-primary mt-4">{(isSubmitting || formStatus === 1) ? "Submitting" : "Submit"}</button>
            </Form>
        </>}
    </Formik>

    return <div className="view recruiter leave-review">
        <SEO title={"Leave a Review | Right Recruit"} slug="" description="" image="" locale="en_GB" type="website" />
        <SearchBar type="recruiters"/>
        <div className="container">
            <div className="row">
                {{  0: LOADING_CONTENT,
                    1: LOADING_CONTENT,
                    2: <>
                        {reviewTokenValid !== 1 ? <div className="col-12">Recruiter Not Found</div> : <>
                            <div className="col-12">
                                <h1>Leave a Review for {reviewRecName} </h1>
                                <div className="rr-card">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            {{
                                                0: FORM,
                                                1: FORM,
                                                2: FORM,
                                                3: <>Thank You</>,
                                                4: <>Already Submitted</>
                                            }[formStatus]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>
                   }[initLoading]}
            </div>
        </div>
    </div>
}
