import React, {useEffect, useState} from "react";
import {SEO} from "@bytehogs/design-system";
import {Redirect} from "react-router-dom";
import {RecruiterAddFilters} from "../../components/recruiters/RecruiterAddFilters";

export const RecruiterAddDetails = props => {
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
    }, []);

    const handleSuccess = () => {
        setRedirect('/create-account/invite-users');
    }

    if(redirect) return <Redirect to={redirect}/>
    return <div className="view auth add-details">
        <SEO title="Add Details | Right Recruit" slug="/create-account/details" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Recruiter Details</h1>
                <RecruiterAddFilters onSuccess={handleSuccess} setup/>
            </div>
        </div>
    </div>
}
