import React from "react";
import {RecruiterRatingRow} from "./RecruiterRatingRow";
import {Link} from "react-router-dom";

export const RecruiterInfoCard = props => {
    const {recruiter} = props;
    const {name, avgCompanyRating, totalCompanyRaters, avgCandidateRating, totalCandidateRaters, linkedin, twitter, website, facebook, logoUrl} = recruiter;
    const anyLinks = (linkedin || twitter || website || facebook);
    return <div className="recruiter-info-card">
        <div className="row">
              <div className="col-md-3">
                  {logoUrl ? <img src={logoUrl} className="rec-logo"/> : <div className="img"/>}
              </div>
              <div className="col-md-9">
                  <h1 className="title">{name}</h1>
                  <RecruiterRatingRow
                      avgCompanyRating={avgCompanyRating}
                      totalComs={totalCompanyRaters}
                      avgCandidateRating={avgCandidateRating}
                      totalCands={totalCandidateRaters}/>
                  {anyLinks && <>
                      <span className="sub-title">Links</span>
                      <div className="social-links">
                          {website && <a href={website} target="_blank" rel="noopener noreferrer"><span className="fas fa-globe"/></a>}
                          {linkedin && <a href={linkedin} target="_blank" rel="noopener noreferrer"><span className="fab fa-linkedin"/></a>}
                          {twitter && <a href={twitter} target="_blank" rel="noopener noreferrer"><span className="fab fa-twitter-square"/></a>}
                          {facebook && <a href={facebook} target="_blank" rel="noopener noreferrer"><span className="fab fa-facebook-square"/></a>}
                      </div>
                  </>}
              </div>
            <div className="col-12">
                <a href="#contact" className="btn btn-secondary">Contact</a>
                <Link to={'/recruiters/leave-review/' + recruiter.testimonialToken} className="btn btn-primary">Leave Testimonial</Link>
            </div>
        </div>
    </div>
};
