import React from "react";
import {RatingContainer} from "../RatingContainer";

export const RecruiterRatingRow = props => {
    const {avgCompanyRating, totalComs, avgCandidateRating, totalCands } = props;
    return <div className="row recruiter-rating-row">
        <div className="col-md-6">
            <span className="sub-title">Company Rating</span>
            <div className="rating-wrapper">
                <RatingContainer rating={avgCompanyRating} company hideRating/>
                <span className="rating-count">({totalComs || 0})</span>
            </div>
        </div>
        <div className="col-md-6">
            <span className="sub-title">Candidate Rating</span>
            <div className="rating-wrapper">
                <RatingContainer rating={avgCandidateRating} hideRating/>
                <span className="rating-count">({totalCands || 0})</span>
            </div>
        </div>
    </div>
}
