import React, {useEffect, useState} from "react";

export const Pagination = props => {
    const {resultsPerPage, totalResults, currentPage, goToPage, loading} = props;
    const [pages, setPages] = useState(0);

    useEffect(() => {
        let pageNumber = Math.ceil(totalResults / resultsPerPage);
        setPages(pageNumber);
    }, [resultsPerPage, totalResults, currentPage]);

    if(pages <= 1) return null;
    return <div className="pagination">
        {/*{!loading && Array.from({length: pages}, (_, i) => i + 1).map(page => {
            return <div key={page} className={currentPage === page ? "active" : ""} onClick={() => goToPage(page)}>{page}</div>
        })}*/}
        {!loading && <>
            {currentPage > 1 && <div onClick={() => goToPage(currentPage - 1)}>Previous</div>}
            {pages > currentPage && <div onClick={() => goToPage(currentPage + 1)}>Next</div>}
        </>}
    </div>
}
