import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {SEO} from "@bytehogs/design-system";
import {LoginFormValidation} from "../../lib/formValidation";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Link, Redirect} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";

export const Login = props => {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const {isAuthed, loginErr} = useSelector(_S => ({
        isAuthed: _S.AuthReducer.isAuthed,
        loginErr: _S.AuthReducer.loginErr,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {login} = bindActionCreators(AuthActionCreators, dispatch);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        if(isAuthed) setRedirect(true);
    }, []);

    useEffect(() => {
        if(loginErr) {
            setFormError(loginErr);
            setLoading(false);
        }
        else if(isAuthed) setRedirect(true);
    }, [loginErr, isAuthed]);

    const handleSubmit = async (values, {setSubmitting}) => {
        const {email, password} = values;
        setLoading(true);
        setFormError(null);
        try {
            login(email, password);
        } catch(e) {
            setFormError(e.message);
            setLoading(false);
        } finally {
            setSubmitting(false);
        }
    }

    if(redirect) return <Redirect to="/recruiters"/>
    return <div className="view auth login">
        <SEO title="Login to Right Recruit" slug="/login" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <div className="auth-card">
                <h1>Login</h1>
                <Link to="/create-account" className="auth-link">No Account? Create one now.</Link>
                <Formik
                    initialValues={{email: '', password: ''}}
                    validationSchema={LoginFormValidation}
                    onSubmit={handleSubmit}>
                    {({isSubmitting}) => <>
                        <Form>
                            <label htmlFor="email" className="sub-title">Email</label>
                            <Field className="form-control" name="email" type="email"/>
                            <ErrorMessage name="email" component="div"
                                          className="input-error"/>
                            <label htmlFor="password" className="sub-title">Password</label>
                            <Field className="form-control" name="password" type="password"/>
                            <ErrorMessage name="password" component="div"
                                          className="input-error"/>
                            {formError && <div className="input-error">{formError}</div>}
                            <div>
                                <Link to="/forgot-password" className="auth-link">Forgot Password?</Link>
                            </div>
                            <button type="submit"
                                    className="btn btn-primary mt-4">{(isSubmitting || loading) ? "Logging In" : "Login"}</button>
                        </Form>
                    </>}
                </Formik>
            </div>
        </div>
    </div>
}
