import React, {useEffect, useState} from "react";
import {SelectFilters} from "../filters/SelectFilters";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../redux/actions/company";

export const RecruiterAddFilters = props => {
    const {onSuccess, setup = false} = props;
    const [setupComplete, setSetupComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({city: [], industry: [], role: []});
    const [errors, setErrors] = useState({city: null, industry: null, role: null, form: null});

    const {companyDetails, filters, companyErr, companySuccess} = useSelector(_S => ({
        filters: _S.CompanyReducer.filters,
        companyErr: _S.CompanyReducer.companyErr,
        companySuccess: _S.CompanyReducer.companySuccess,
        companyDetails: _S.CompanyReducer.companyDetails
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getFilters, updateFilters, getCompany} = bindActionCreators(CompanyActionCreators, dispatch);

    useEffect(() => {
        if(filters.length === 0) {
            getFilters();
        }
        if(!companyDetails) {
            getCompany();
        }
    }, []);

    useEffect(() => {
        if(filters.length !== 0 && !setupComplete) {
            let theseValues = {city: [], industry: [], role: []};
            companyDetails?.filters.forEach(F => {
                const theFilter = filters[F] || {};
                if (theFilter.type === 'city') theseValues.city.push({value: F, label: theFilter.name});
                if (theFilter.type === 'industry') theseValues.industry.push({value: F, label: theFilter.name});
                if (theFilter.type === 'role') theseValues.role.push({value: F, label: theFilter.name});
            });
            setValues(theseValues);
            setSetupComplete(true);
        }
    }, [companyDetails, filters]);

    useEffect(() => {
        if(loading && companyErr) {
            setLoading(false);
            setErrors({city: null, industry: null, role: null, form: companyErr});
        } else if(loading && companySuccess) {
            setLoading(false);
            getCompany();
            onSuccess()
        }
    }, [companySuccess, companyErr]);

    const handleChange = (newValues, type) => {
        setValues({...values, [type]: newValues});
    }

    const handleSubmit = () => {
        const {city, industry, role} = values;
        setLoading(true);
        let theseErrors = {city: null, industry: null, role: null, form: null};
        setErrors(theseErrors);
        if(city.length === 0) theseErrors.city = "Cities are required";
        if(industry.length === 0) theseErrors.industry = "Industries are required";
        if(role.length === 0) theseErrors.role = "Job Roles are required";
        if(theseErrors.city || theseErrors.industry || theseErrors.role) {
            setErrors(theseErrors);
            setLoading(false);
        } else {
            let theseFilters = [];
            const all = [...city, ...industry, ...role];
            all.forEach(x => theseFilters.push(x.value));
            updateFilters(theseFilters);
        }
    }

    if(!companyDetails || filters.length === 0) return null;
    return <>
        <label className="sub-title">CITIES YOU OPERATE IN</label>
        <SelectFilters
            type="city"
            setValues={handleChange}
            values={values.city}
            error={errors.city}
        />
        <label className="sub-title">SECTORS OR INDUSTRIES YOU OPERATE IN</label>
        <SelectFilters
            type="industry"
            setValues={handleChange}
            values={values.industry}
            error={errors.industry}
        />
        <label className="sub-title">JOB ROLES YOU COMMONLY RECRUIT FOR</label>
        <SelectFilters
            type="role"
            setValues={handleChange}
            values={values.role}
            error={errors.role}
        />
        <button className="btn btn-secondary" onClick={handleSubmit} disabled={loading}>{loading ? "Saving" : (setup ? "Next" : "Save")}</button>
    </>
}
