import React, {useEffect, useState} from "react";
import {AccountNavigation} from "../../components/account/AccountNavigation";
import {SEO} from "@bytehogs/design-system";
import {COMPANY_FORM} from "../../lib/forms";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../redux/actions/company";

export const CompanyDetails = props => {
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const {companyDetails, companyErr, companySuccess} = useSelector(_S => ({
        companyErr: _S.CompanyReducer.companyErr,
        companySuccess: _S.CompanyReducer.companySuccess,
        companyDetails: _S.CompanyReducer.companyDetails,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getCompany, updateCompany} = bindActionCreators(CompanyActionCreators, dispatch);

    const handleSubmit = values => {
        const {name, bio, city, country, email, phone, website, linkedin, facebook, twitter, logo} = values;
        setLoading(true);
        setFormError(null);
        try {
            const theData = {isRecruiter: companyDetails.isRecruiter, name, bio, city, country, email, phone, website, linkedin, facebook, twitter, logo}
            updateCompany(theData);
        } catch(e) {
            setFormError(e.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if(companyErr && loading) {
            setFormError(companyErr);
            setLoading(false);
        } else if(companySuccess && loading) {
            setLoading(false);
            getCompany();
        }
    }, [companyErr, companySuccess]);

    return <div className="view account company">
        <SEO title="Manage Your Company | Right Recruit" slug="/account/company" description="" image="" locale="en_GB" type="website" />
        <div className="container">
            <AccountNavigation/>
            <div className="account-card">
                <h4>{companyDetails?.isRecruiter === 1 ? "Recruiter" : "Company"} Details</h4>
                {companyDetails ? <COMPANY_FORM handleSubmit={handleSubmit} loading={loading} formError={formError} isRecruiter={companyDetails?.isRecruiter === 1} initialValues={companyDetails}/> : <div>Loading</div>}
            </div>
        </div>
    </div>
}
