import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {CompanyActionCreators} from "../redux/actions/company";
import {RecruitersActionCreators} from "../redux/actions/recruiters";
import {useHistory, useLocation} from "react-router-dom/cjs/react-router-dom";

export const SearchBar = props => {
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [location, setLocation] = useState(null);
    const LOC = useLocation();
    const history = useHistory();
    const {filters,recruiters} = useSelector(_S => ({
        filters: _S.CompanyReducer.filters,
        recruiters: _S.RecruitersReducer.recruiters,
    }), shallowEqual);
    const dispatch = useDispatch();
    const {getFilters} = bindActionCreators(CompanyActionCreators, dispatch);
    const {search} = bindActionCreators(RecruitersActionCreators, dispatch);
    const {type = 'recruiters'} = props;

    useEffect(() => {
        if(filters.length === 0) {
            getFilters();
        }
        const SearchParams = new URLSearchParams(LOC.search);
        const theQuery = SearchParams.get('query') || "";
        const theCity = SearchParams.get('city');
        setQuery(theQuery);
        setLocation(theCity);
    }, []);

    useEffect(() => {
        if(loading) {
            let searchParams = {};
            if(query && query.length > 0) searchParams.query = query;
            if(location) searchParams.city = location
            /*if (!['/recruiters', '/recruiters/'].includes(pathname)) {
                history.push({
                    pathname: '/recruiters',
                    search: "?" + new URLSearchParams(searchParams).toString()
                });
            } else {*/
                history.push({
                    pathname: '/recruiters',
                    search: "?" + new URLSearchParams(searchParams).toString()
                });
            //}
            setLoading(false);
        }
    }, [recruiters]);

    const handleSearch = () => {
        if(!loading) {
            setLoading(true);
            search(query, Number(location) || null)
        }
    }

    const locations = Object.values(filters).filter(X => X.type === 'city');
    return <section className={"search-bar " + type}>
        <div className="container">
            <div className="search-group">
                <input type="text" className="form-control search-box" placeholder="Search by job, industry, recruiter name" value={query} onChange={e => setQuery(e.target.value)}/>
                <select className="location-selection" value={location} onChange={e => setLocation(e.target.value)}>
                    <option value="null">Any Location</option>
                    {locations.map(L => <option key={L.id} value={L.id}>{L.name}</option>)}
                </select>
                {loading ? <div className="loader"><i className="fas fa-spinner fa-spin"/></div> : <img src="//client-upload.bytehogs.com/rr/icons/search.svg" className="search-button"
                      onClick={handleSearch}/>}
            </div>
        </div>
    </section>
}
